import {useContext} from 'react'
import {Icon} from '../../icon'
import {OptionsContext} from '../../../App.js'
import {configName} from '../../../helpers/constants.js'
import './reset.css'


export default function Reset() {

    const { config, defaultConfig, showOptionPanel } = useContext( OptionsContext )

    function handlerResetButton() {

        Object.keys(config).forEach(key => {
            config[key].setValue(value => {
                if (key === configName.size) {
                    value.width = defaultConfig[key].width
                    value.depth = defaultConfig[key].depth
                } else {
                    value.optionsSelected = [...defaultConfig[key].optionsSelected]
                }
                return {...value}
            })
        })
    }

    return (
        <button
            type="button"
            className={ `reset_options_button ${showOptionPanel ? '' : 'desktop_hide'}` }
            onClick={ handlerResetButton }
            title="Reset Options"
        >
            <span className="title">Aktualisieren</span>
            <Icon icon="reset" className="reset_options_button__icon" />
        </button>
    )
}