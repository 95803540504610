import {useCallback, useContext, useEffect, useRef} from 'react'
import {OptionsContext} from '../../../App.js'
// import {setPriceForTypeWoodGroup} from '../../../helpers/functions.js'
import {Icon} from '../../icon'
import './size.css'
import {configName} from "../../../helpers/constants.js";

export default function Size({ option, minMax }) {
    const { name: sizeName, title: sizeTitle } = option
    const { config } = useContext( OptionsContext )
    const inputRef = useRef()
    const intervalRef = useRef(null)

    useEffect(() => {
        config[configName.size].setValue(value => {
            if (value[sizeName] < minMax.min) {
                value[sizeName] = minMax.min
            }
            if (value[sizeName] > minMax.max) {
                value[sizeName] = minMax.max
            }

            return {...value}
        })

    }, [minMax])

    function changeSize( isPlus ) {

        config[configName.size].setValue(value => {

            let newValue = Number(( value[sizeName] + (isPlus ? .1 : -.1) ).toFixed(2))

            if (newValue < minMax.min || newValue > minMax.max) {
                newValue = value[sizeName]
            }

            value[sizeName] = newValue

            return {...value}
        })

    }

    function handleButtonClick( event ) {
        changeSize( event.currentTarget.classList.contains('increase') )
    }

    /** KeyDown Event */
    const keyPressCallback = useCallback( event =>  {
        if (document.activeElement === inputRef.current) {
            if (event.key === "ArrowUp") {
                inputRef.current.parentElement.querySelector('.increase')?.click()
            }
            if (event.key === "ArrowDown") {
                inputRef.current.parentElement.querySelector('.decrease')?.click()
            }
        }
    }, []);
    useEffect(() => {
        document.addEventListener("keydown", keyPressCallback, false);

        return () => {
            document.removeEventListener("keydown", keyPressCallback, false)
            handleButtonMouseUp()
        };
    }, [ keyPressCallback ] );
    /* -- KeyDown Event */

    function handleButtonMouseDown( event ) {
        if (intervalRef.current) return

        const isPlus = event.currentTarget.classList.contains('increase')

        intervalRef.current = setInterval(() => {
            changeSize( isPlus )
        }, 144 )
    }

    function handleButtonMouseUp() {
        if ( intervalRef.current ) {
            clearInterval( intervalRef.current )
            intervalRef.current = null
        }
    }

    return (
        <div className="input_size">
            <label htmlFor={ "model_" + sizeName } className="font-text">{ sizeTitle }</label>
            <div className="input_wrapper">

                <button type="button" className={ "decrease " + sizeName }
                        onClick={ handleButtonClick }
                        onMouseDown={ handleButtonMouseDown }
                        onMouseUp={ handleButtonMouseUp }
                        onMouseLeave={ handleButtonMouseUp }>
                    <Icon icon="arrow_left" className="icon" />
                </button>
                <input type="text" key={ sizeName } ref={ inputRef } id={ "model_" + sizeName }
                       name={ "sauna_" + sizeName }
                       value={ ( config[configName.size].value[sizeName] * 100 ).toFixed(0) + ' cm' }
                       onChange={ e => e.preventDefault() }/>

                <button type="button" className={ "increase " + sizeName }
                        onClick={ handleButtonClick }
                        onMouseDown={ handleButtonMouseDown }
                        onMouseUp={ handleButtonMouseUp }
                        onMouseLeave={ handleButtonMouseUp }>
                    <Icon icon="arrow_right" className="icon" />
                </button>

            </div>
        </div>
    )
}