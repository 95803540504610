import React, {useContext, useEffect, useMemo, useRef} from 'react'
import {Base, Geometry, Subtraction} from '@react-three/csg'
import AthenVerticalBars from './AthenVerticalBars.js'
import AthenWatermark from './AthenWatermark.js'
import Door from '../Door'
import Glass from '../Glass'
import * as THREE from 'three'
import {OptionsContext} from '../../../App.js'
import {additionalOptionName, configName} from '../../../helpers/constants.js'
import {getParamWall, getRendererOptions} from '../../../helpers/functions.js'

export default function AthenWalls({size, texture}) {

    const params = useMemo(() => {

        return {
            wall: getParamWall('athen', size),
            geometry: {
                wallX: new THREE.BoxGeometry(size.model.width - .001, size.model.height - .001, size.model.wallThickness),
                wallZ: new THREE.BoxGeometry(size.model.depth - .001, size.model.height - .001, size.model.wallThickness),
            },
        }

    }, [size] )

    return (
        <>
            <AthenWall wall="corner" size={size} texture={texture} params={params} textureKey={'w'}/>
            <AthenWall wall="front" size={size} texture={texture} params={params} textureKey={'w'}/>
            <AthenWall wall="right" size={size} texture={texture} params={params} textureKey={'d'}/>
            <AthenWall wall="left" size={size} texture={texture} params={params} textureKey={'d'}/>
            <AthenWall wall="back" size={size} texture={texture} params={params} textureKey={'w'}/>
        </>
    )
}

function AthenWall({wall, size, texture, params, textureKey}) {
    const { config } = useContext( OptionsContext )

    const doorRef = useRef()
    const doorwayRef = useRef()
    const windowFrontRef = useRef()
    const windowRightRef = useRef()

    /** wall_corner: glaseck, teilglasfront */
    useEffect(() => {
        const glassElement = getRendererOptions({ config, groupName: configName.glassElem, selected: true })
        let side = glassElement?.additional_options.options.find(o => o.selected).name
        side = side === additionalOptionName.left ? 'L' : 'R'

        if (wall === 'corner') {
            if (!doorRef.current || !doorwayRef.current) return

            const glassDoor = doorRef.current.children[0].children.find(elem => elem.name === 'glass')
            const doorHandle = doorRef.current.children[0].children.find(elem => elem.name === 'door_handle')

            if (size.glassElem.name === 'glaseck' || size.glassElem.name === 'teilglasfront') {
                const newDoorWidth = size.door.width + size.model.distance - .01
                const newDoorwayWidth = size.door.width + size.model.distance
                doorwayRef.current.scale.x = newDoorwayWidth / size.door.width
                doorwayRef.current.position.x = side === 'L' ? (newDoorwayWidth - size.door.width) / 2 : - (newDoorwayWidth - size.door.width) / 2
                glassDoor.scale.x = newDoorWidth / size.door.width
                glassDoor.position.x = side === 'L' ? (newDoorWidth - size.door.width) / 2 : - (newDoorWidth - size.door.width) / 2
                doorHandle.position.x = side === 'L' ? (newDoorWidth - size.door.width) / 2 : - (newDoorWidth - size.door.width) / 2
            } else {
                doorwayRef.current.scale.x = 1
                doorwayRef.current.position.x = 0
                glassDoor.scale.x = 1
                glassDoor.position.x = 0
                doorHandle.position.x = 0
            }
        }

    }, [size, config[configName.glassElem].value])

    return (
        <group name={wall} position={params.wall[wall].position} rotation={params.wall[wall].rotation}>

            <AthenVerticalBars size={size} wall={wall} texture={texture} />

            <AthenWatermark size={size} wall={wall} />

            <mesh>
                <Geometry useGroups>
                    <Base>
                        <boxGeometry args={[
                            wall === 'corner' ? size.model.wCorner
                                : wall === 'front' ? size.model.wFront
                                    : wall === 'right' ? size.model.wRight
                                        : wall === 'left' ? size.model.depth - .001
                                            : wall === 'back' ? size.model.width - .001 : 0,
                            size.model.height,
                            size.model.wallThickness]} />
                        <meshStandardMaterial attach="material-0" map={texture.in.bar.ver}/>
                        <meshStandardMaterial attach="material-1" map={texture.in.bar.ver}/>
                        <meshStandardMaterial attach="material-2" map={texture.in.bar.hor}/>
                        <meshStandardMaterial attach="material-3" map={texture.in.bar.hor}/>
                        <meshStandardMaterial attach="material-4" map={texture.in.wall[textureKey]}/>
                        <meshStandardMaterial attach="material-5" map={texture.out.wall[textureKey]}/>
                    </Base>

                    {
                        wall === 'corner' && (
                            <group name="doorway"
                                   position={[0, - (size.model.height - size.door.height) / 2 - .001, - .005]}>
                                <Subtraction ref={doorwayRef}>
                                    <boxGeometry args={[size.door.width, size.door.height, size.model.wallThickness + .01]}/>
                                    <meshStandardMaterial attach="material-0" map={texture.in.bar.ver}/>
                                    <meshStandardMaterial attach="material-1" map={texture.in.bar.ver}/>
                                    <meshStandardMaterial attach="material-2" map={texture.in.bar.hor}/>
                                    <meshStandardMaterial attach="material-3" map={texture.in.bar.hor}/>
                                    <meshStandardMaterial attach="material-4"/>
                                    <meshStandardMaterial attach="material-5"/>
                                </Subtraction>
                                <group ref={doorRef}>
                                    <Door
                                        size={size}
                                        geometry={new THREE.BoxGeometry(
                                            size.door.width - size.door.clearance * 2,
                                            size.door.height - size.door.clearance * 2,
                                            size.door.depth)}
                                        position-z={- .025}
                                    />
                                </group>
                            </group>
                        )
                    }

                    {
                        wall === 'front' && (
                            <>
                                {
                                    (size.glassElem.name === '1glas' && size.glassElem.side === 'L' || size.glassElem.name === '2glas') &&
                                    <group name="window_1" ref={windowFrontRef} position={
                                        [
                                            - (size.model.wFront / 2 - size.window.front.width / 2 - size.model.distance),
                                            (size.door.height - size.model.height / 2) - (size.window.front.height / 2),
                                            0
                                        ]
                                    }>
                                        <Subtraction name="subtraction">
                                            <boxGeometry args={[
                                                size.window.front.width,
                                                size.window.front.height,
                                                size.model.wallThickness]}
                                            />
                                            <meshStandardMaterial attach="material-0" map={texture.in.bar.ver}/>
                                            <meshStandardMaterial attach="material-1" map={texture.in.bar.ver}/>
                                            <meshStandardMaterial attach="material-2" map={texture.in.bar.hor}/>
                                            <meshStandardMaterial attach="material-3" map={texture.in.bar.hor}/>
                                            <meshStandardMaterial attach="material-4"/>
                                            <meshStandardMaterial attach="material-5"/>
                                        </Subtraction>
                                        <Glass geometry={new THREE.BoxGeometry(
                                            size.window.front.width,
                                            size.window.front.height,
                                            size.model.wallThickness - .02)}/>
                                    </group>
                                }

                                {
                                    (['glaseck', 'teilglasfront'].includes(size.glassElem.name) && size.glassElem.side === 'L') &&
                                    <group ref={windowFrontRef} position={[
                                        size.glassElem.name === 'teilglasfront'
                                            ? - size.model.wallThickness / 2
                                            : - (size.model.wFront / 2 - size.window.front.width / 2 - size.model.distance + size.model.wallThickness / 2),
                                        - (size.model.height - size.door.height) / 2 - .001,
                                        0]}>
                                        <Subtraction name="subtraction">
                                            <boxGeometry args={[
                                                size.glassElem.name === 'teilglasfront'
                                                    ? size.model.wFront - size.model.wallThickness
                                                    : size.window.front.width + size.model.wallThickness,
                                                size.door.height,
                                                size.model.wallThickness]}
                                            />
                                            <meshStandardMaterial attach="material-0" map={texture.in.bar.ver}/>
                                            <meshStandardMaterial attach="material-1" map={texture.in.bar.ver}/>
                                            <meshStandardMaterial attach="material-2" map={texture.in.bar.hor}/>
                                            <meshStandardMaterial attach="material-3" map={texture.in.bar.hor}/>
                                            <meshStandardMaterial attach="material-4"/>
                                            <meshStandardMaterial attach="material-5"/>
                                        </Subtraction>
                                        <Glass geometry={new THREE.BoxGeometry(
                                            size.glassElem.name === 'teilglasfront'
                                                ? size.model.wFront - size.model.wallThickness
                                                : size.window.front.width + size.model.wallThickness - .004,
                                            size.door.height,
                                            size.door.depth)}
                                               position={[.002, 0, - (size.model.wallThickness / 2 - size.door.depth / 2 - .012)]}/>
                                    </group>
                                }
                            </>
                        )
                    }

                    {
                        wall === 'right' && (
                            <>
                                {(size.glassElem.name === '1glas' && size.glassElem.side === 'R' || size.glassElem.name === '2glas') &&
                                    <group ref={windowRightRef} position={
                                        [
                                            (size.model.wRight / 2 - size.window.right.width / 2 - size.model.distance),
                                            (size.door.height - size.model.height / 2) - (size.window.right.height / 2),
                                            0
                                        ]
                                    }>
                                        <Subtraction name="subtraction">
                                            <boxGeometry args={[
                                                size.window.right.width,
                                                size.window.right.height,
                                                size.model.wallThickness]}
                                            />
                                            <meshStandardMaterial attach="material-0" map={texture.in.bar.ver}/>
                                            <meshStandardMaterial attach="material-1" map={texture.in.bar.ver}/>
                                            <meshStandardMaterial attach="material-2" map={texture.in.bar.hor}/>
                                            <meshStandardMaterial attach="material-3" map={texture.in.bar.hor}/>
                                            <meshStandardMaterial attach="material-4"/>
                                            <meshStandardMaterial attach="material-5"/>
                                        </Subtraction>
                                        <Glass geometry={new THREE.BoxGeometry(
                                            size.window.right.width,
                                            size.window.right.height,
                                            size.model.wallThickness - .02)}/>
                                    </group>
                                }


                                {(['glaseck', 'teilglasfront'].includes(size.glassElem.name) && size.glassElem.side === 'R') &&
                                    <group ref={windowFrontRef} position={[
                                        size.glassElem.name === 'teilglasfront'
                                            ? size.model.wallThickness / 2
                                            : size.model.wRight / 2 - size.window.right.width / 2 - size.model.distance + size.model.wallThickness / 2,
                                        - (size.model.height - size.door.height) / 2 - .001,
                                        0]}>
                                        <Subtraction name="subtraction">
                                            <boxGeometry args={[
                                                size.glassElem.name === 'teilglasfront'
                                                    ? size.model.wRight - size.model.wallThickness
                                                    : size.window.right.width + size.model.wallThickness,
                                                size.door.height,
                                                size.model.wallThickness]}
                                            />
                                            <meshStandardMaterial attach="material-0" map={texture.in.bar.ver}/>
                                            <meshStandardMaterial attach="material-1" map={texture.in.bar.ver}/>
                                            <meshStandardMaterial attach="material-2" map={texture.in.bar.hor}/>
                                            <meshStandardMaterial attach="material-3" map={texture.in.bar.hor}/>
                                            <meshStandardMaterial attach="material-4"/>
                                            <meshStandardMaterial attach="material-5"/>
                                        </Subtraction>
                                        <Glass geometry={new THREE.BoxGeometry(
                                            size.glassElem.name === 'teilglasfront'
                                                ? size.model.wRight - size.model.wallThickness
                                                : size.window.right.width + size.model.wallThickness - .004,
                                            size.door.height,
                                            size.door.depth)}
                                               position={[- .002, 0, - (size.model.wallThickness / 2 - size.door.depth / 2 - .012)]}/>
                                    </group>
                                }
                            </>
                        )
                    }
                </Geometry>
            </mesh>
        </group>
    )
}
