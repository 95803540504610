import React, {useContext} from 'react'
import {configName} from '../../helpers/constants'
import {OptionsContext} from '../../App.js'

export default function Ceiling({size, texture}) {

    const { config } = useContext( OptionsContext )

    const cassetteDesign = config[configName.construction2].value.optionsSelected[0]

    const railWidth = size.model.wallThickness
    const railVerPosZ = size.model.wallThickness/2 + .0002
    const posY = size.model.depth/2 - railWidth/2
    let arrayBars

    if (size.model.depth > 1.7) {
        const posMiddle = (size.model.depth - railWidth)/6
        arrayBars = [posY, posMiddle, - posMiddle, - posY]
    } else {
        arrayBars = [posY, 0, - posY]
    }

    const Bars = ({ name, position, firstOrLast }) => (
        <mesh name={name} position={position}>
            <planeGeometry args={[size.model.width, name === 'external' && firstOrLast ? railWidth/2 : railWidth]} />
            <meshStandardMaterial
                map={ name === 'external' ? texture.out.bar.hor : texture.in.bar.hor}
                side={ name === 'external' ? 0 : 1}
            />
        </mesh>
    )

    const bars = arrayBars.map((x, i, array) => {
        return (
            <group key={i}>
                <Bars name="external" position={[0, x, railVerPosZ]}/>

                {
                    cassetteDesign.id === 0
                        ? <Bars
                            name="internal"
                            position={[0, x, - railVerPosZ]}
                            firstOrLast={(i === 0) || (i === array.length - 1)}
                        />
                        : null
                }
            </group>
        )
    })


    return (
        <group name="ceiling"
               position={[size.model.width / 2 + .0005, size.model.height - .0399, size.model.depth / 2 + .0005]}
               rotation-x={-Math.PI / 2}>

            { bars }

            <mesh>
                <boxGeometry args={[size.model.width - .002, size.model.depth - .002, size.model.wallThickness]} />
                <meshStandardMaterial attach="material-0" map={texture.in.bar.ver}/>
                <meshStandardMaterial attach="material-1" map={texture.in.bar.ver}/>
                <meshStandardMaterial attach="material-2" map={texture.in.bar.hor}/>
                <meshStandardMaterial attach="material-3" map={texture.in.bar.hor}/>
                <meshStandardMaterial attach="material-4" map={texture.out.ceiling}/>
                <meshStandardMaterial attach="material-5" map={texture.in.ceiling}/>
            </mesh>
        </group>
    )
}
