import './additional.css'

export default function Additional({ option, configGroup }) {
    function handleSelectAdditionalOption(value) {
        if (value._available && value.available) {

            configGroup.setValue(val => {

                val.options.forEach(opt => {
                    if (opt.additional_options.options.length > 1) {
                        opt.additional_options.options.forEach(o => o.selected = value.name === o.name)
                    }
                })

                return {...val}
            })
        }
    }

    return (
        <div className="additional_options">
            <h3 className="additional_option__title">{ option.additional_options.title }</h3>

            <ul className="additional_option__radio_buttons">
                {
                    option.additional_options.options.map( value => {
                        return (
                            <li className="additional_option__radio_button" key={value.title}>
                                <label className={`additional_option__radio ${
                                    value.selected ? 'radio--checked' : ''
                                } ${
                                    value.available && value._available ? 'additional_option__radio--available' : ''
                                }`}>
                                    <input type="radio" onClick={ () => { handleSelectAdditionalOption(value) } } />
                                    <span className="label">{ value.title }</span>
                                </label>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}
