/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 Tuerband_schwarz_B3308_S02_scaled_e_mirror.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF('/assets/models/fittings/Tuerband_schwarz_B3308_S02_scaled_e_mirror.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cylinder005.geometry} material={materials['Material.003']} position={[-0.014, 0.624, 0.089]} scale={[0.144, 0.005, 0.144]} />
      <mesh geometry={nodes.Cylinder004.geometry} material={materials['Material.003']} position={[-0.014, 1.856, 0.089]} scale={[0.144, 0.005, 0.144]} />
      <mesh geometry={nodes.Cylinder003.geometry} material={materials['Material.001']} position={[-0.014, 2.147, 0.091]} rotation={[-Math.PI, -Math.PI / 2, 0]} scale={[0.144, 0.287, 0.144]} />
      <mesh geometry={nodes.Cylinder002.geometry} material={materials['Material.001']} position={[-0.017, 1.239, 0.091]} rotation={[0, Math.PI / 2, 0]} scale={[0.144, 0.61, 0.144]} />
      <mesh geometry={nodes.Cylinder001.geometry} material={materials['Material.001']} position={[-0.014, 0.332, 0.091]} rotation={[0, Math.PI / 2, 0]} scale={[0.144, 0.287, 0.144]} />
      <mesh geometry={nodes.Circle012.geometry} material={materials['Material.004']} position={[-0.876, 1.212, 0.185]} rotation={[-Math.PI / 2, 0, Math.PI]} scale={[-1.083, -0.66, -1.083]} />
      <mesh geometry={nodes.Circle011.geometry} material={materials['Material.004']} position={[-0.876, 1.212, 0.256]} rotation={[-Math.PI / 2, 0, Math.PI]} scale={[-1.083, -0.66, -1.083]} />
      <mesh geometry={nodes.Circle010.geometry} material={materials['Material.001']} position={[-0.732, 1.243, 0.313]} rotation={[-Math.PI / 2, 0, Math.PI]} scale={[-1.644, -1.499, -1.234]} />
      <group position={[-0.01, 1.237, -0.028]} rotation={[Math.PI / 2, 0, 0]} scale={1.202}>
        <mesh geometry={nodes.Circle003_1.geometry} material={materials['Material.001']} />
        <mesh geometry={nodes.Circle003_2.geometry} material={materials['Material.001']} />
      </group>
      <mesh geometry={nodes.Bolt008.geometry} material={materials['Iron Touched']} position={[-0.647, 0.823, 0.136]} rotation={[Math.PI / 2, 0, 0.012]} scale={[49.741, 87.335, 49.641]} />
      <mesh geometry={nodes.Bolt007.geometry} material={materials['Iron Touched']} position={[-0.647, 1.75, 0.136]} rotation={[Math.PI / 2, 0, 0.012]} scale={[49.741, 87.335, 49.641]} />
      <group position={[-0.647, 0.823, 0.33]} rotation={[Math.PI / 2, 0, 0.012]} scale={[49.634, 9.324, 49.641]}>
        <mesh geometry={nodes.Bolt005_1.geometry} material={materials['Iron Touched']} />
        <mesh geometry={nodes.Bolt005_2.geometry} material={materials['Material.006']} />
      </group>
      <group position={[-0.647, 1.75, 0.33]} rotation={[Math.PI / 2, 0, 0.012]} scale={[49.634, 9.324, 49.641]}>
        <mesh geometry={nodes.Bolt002_1.geometry} material={materials['Iron Touched']} />
        <mesh geometry={nodes.Bolt002_2.geometry} material={materials['Material.006']} />
      </group>
      <mesh geometry={nodes.Circle001.geometry} material={materials['Material.004']} position={[0.846, 1.212, 0.185]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[1.083, 0.66, 1.083]} />
      <mesh geometry={nodes.Circle002.geometry} material={materials['Material.004']} position={[0.846, 1.212, 0.256]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[1.083, 0.66, 1.083]} />
      <mesh geometry={nodes.Circle003.geometry} material={materials['Material.001']} position={[0.702, 1.243, 0.313]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[1.644, 1.499, 1.234]} />
      <group position={[-0.02, 1.237, -0.028]} rotation={[-Math.PI / 2, 0, 0]} scale={-1.202}>
        <mesh geometry={nodes.Circle005.geometry} material={materials['Material.001']} />
        <mesh geometry={nodes.Circle005_1.geometry} material={materials['Material.001']} />
      </group>
      <mesh geometry={nodes.Bolt001.geometry} material={materials['Iron Touched']} position={[0.616, 0.823, 0.136]} rotation={[-Math.PI / 2, 0, 0.012]} scale={[-49.741, -87.335, -49.641]} />
      <mesh geometry={nodes.Bolt002.geometry} material={materials['Iron Touched']} position={[0.616, 1.75, 0.136]} rotation={[-Math.PI / 2, 0, 0.012]} scale={[-49.741, -87.335, -49.641]} />
      <group position={[0.616, 0.823, 0.33]} rotation={[-Math.PI / 2, 0, 0.012]} scale={[-49.634, -9.324, -49.641]}>
        <mesh geometry={nodes.Bolt004_1.geometry} material={materials['Iron Touched']} />
        <mesh geometry={nodes.Bolt004_2.geometry} material={materials['Material.006']} />
      </group>
      <group position={[0.616, 1.75, 0.33]} rotation={[-Math.PI / 2, 0, 0.012]} scale={[-49.634, -9.324, -49.641]}>
        <mesh geometry={nodes.Bolt006_1.geometry} material={materials['Iron Touched']} />
        <mesh geometry={nodes.Bolt006_2.geometry} material={materials['Material.006']} />
      </group>
    </group>
  )
}

// useGLTF.preload('/assets/models/fittings/Tuerband_schwarz_B3308_S02_scaled_e_mirror.glb')
