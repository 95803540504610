import { Icon } from '../../icon'
import {useContext} from 'react'
import { OptionsContext } from '../../../App'
import './tooltip.css'


export default function TooltipButton({ item }) {
    if (!item) return

    const { tooltipRef } = useContext( OptionsContext )
    const { info_title, info_content } = item

    function handlerTooltipButton( item, event ) {
        tooltipRef.current.style.left = 0
        tooltipRef.current.style.top = 0
        tooltipRef.current.classList.remove('hide')
        tooltipRef.current.querySelector('.title').innerText = info_title
        tooltipRef.current.querySelector('.content').innerText = info_content

        const sizes = event.target.getBoundingClientRect()
        const x = sizes.x + sizes.width/2 // event.clientX
        const y = sizes.y + sizes.height/2 // event.clientY

        if (window.innerWidth > 768) {
            tooltipRef.current.style.left = x - tooltipRef.current.clientWidth - 20 + 'rem'
            tooltipRef.current.style.top = y - 40 + 'rem'
        } else {
            const arrow = tooltipRef.current.querySelector('.arrow')
            arrow.classList.remove('flip')

            const x1 = (window.innerWidth - tooltipRef.current.clientWidth)/2
            let positionArrowX

            if (x - x1 < 20) {
                positionArrowX = 20 // Offset - 20 == border-radius of info box
            } else if (window.innerWidth - x1 - x > 0) {
                positionArrowX = x - x1
            } else {
                positionArrowX = tooltipRef.current.clientWidth - 44 // Offset - 44 == width arrow element + border-radius of info box
                arrow.classList.add('flip')
            }

            arrow.style.left = positionArrowX + 'rem'
            tooltipRef.current.style.width = (window.innerWidth - 180) + 'rem'
            tooltipRef.current.style.left = (window.innerWidth - tooltipRef.current.clientWidth)/2 + 'rem'
            tooltipRef.current.style.top = y - tooltipRef.current.clientHeight - 40 + 'rem'
        }
    }

    const button = (
        <button type="button" className="checkbox__tooltip_button" onClick={ handlerTooltipButton.bind( this, item ) }>
            <Icon icon="i" className="icon_info" />
        </button>
    )

    if ( info_content ) {
        return button
    } else {
        return null
    }
}
