/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 Gewerbliches_Tuerband_scaled_e_mirror.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF('/assets/models/fittings/Gewerbliches_Tuerband_scaled_e_mirror.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cylinder.geometry} material={materials['Material.008']} position={[0, 0.465, 0]} scale={[0.206, 0.395, 0.206]} />
      <mesh geometry={nodes.Cylinder001.geometry} material={materials['Material.008']} position={[0, 1.599, 0]} scale={[0.206, 0.733, 0.206]} />
      <mesh geometry={nodes.Cylinder002.geometry} material={materials['Material.008']} position={[0, 2.735, 0]} scale={[0.206, 0.395, 0.206]} />
      <mesh geometry={nodes.Cube.geometry} material={materials['Material.009']} position={[-0.582, 1.679, 0.302]} scale={[0.66, 1.366, 0.044]} />
      <mesh geometry={nodes.Cube001.geometry} material={materials['Material.008']} position={[-0.597, 1.601, 0.645]} rotation={[0, 0, -Math.PI]} scale={[-0.678, -1.526, -0.06]} />
      <mesh geometry={nodes.Cylinder003.geometry} material={materials['Material.008']} position={[0.004, 0.465, 0]} rotation={[-Math.PI, 0, 0]} scale={[-0.206, -0.395, -0.206]} />
      <mesh geometry={nodes.Cylinder004.geometry} material={materials['Material.008']} position={[0.004, 1.599, 0]} rotation={[-Math.PI, 0, 0]} scale={[-0.206, -0.733, -0.206]} />
      <mesh geometry={nodes.Cylinder005.geometry} material={materials['Material.008']} position={[0.004, 2.735, 0]} rotation={[-Math.PI, 0, 0]} scale={[-0.206, -0.395, -0.206]} />
      <mesh geometry={nodes.Cube002.geometry} material={materials['Material.009']} position={[0.587, 1.679, 0.302]} rotation={[-Math.PI, 0, 0]} scale={[-0.66, -1.366, -0.044]} />
      <mesh geometry={nodes.Cube003.geometry} material={materials['Material.008']} position={[0.602, 1.601, 0.645]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.678, 1.526, 0.06]} />
      <mesh geometry={nodes.Cube004.geometry} material={materials['Material.009']} position={[-0.582, 1.679, 0.544]} scale={[0.66, 1.366, 0.044]} />
      <mesh geometry={nodes.Cube005.geometry} material={materials['Material.009']} position={[0.587, 1.679, 0.544]} rotation={[-Math.PI, 0, 0]} scale={[-0.66, -1.366, -0.044]} />
    </group>
  )
}

// useGLTF.preload('/assets/models/fittings/Gewerbliches_Tuerband_scaled_e_mirror.glb')
