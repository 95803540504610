import React, {useContext, useMemo} from 'react'
import * as THREE from 'three'
import {OptionsContext} from '../../App.js'
import AthenWalls from '../elements/athen/AthenWalls.js'
import AthenCeiling from '../elements/athen/AthenCeiling.js'
import Bench from '../bench/Bench.js'
import Oven from '../oven/Oven.js'
import {Base, Geometry, Subtraction} from '@react-three/csg'
import Lamp from '../lamp/Lamp.js'

export default function Athen({ size, texture }) {
    const { ref } = useContext( OptionsContext )

    /** Init Individual Sizes */
    useMemo(() => {
        size.model.wCorner = size.door.width + size.model.distance * 2
        size.model.positionCorner = Math.sqrt(size.model.wCorner * size.model.wCorner / 2 )
        size.model.wFront = size.model.width - size.model.positionCorner - .001
        size.model.wRight = size.model.depth - size.model.positionCorner - .001
        // size.model.sizeSubtraction = size.model.wallThickness * 1.45

        const getWindowWidth = front => {
            const widthWall = front ? size.model.wFront : size.model.wRight
            let ww = widthWall - size.model.wallThickness - size.model.distance

            const breakPoint = 1.7
            const breakPoint2 = 2.3
            const widthModel = front ? size.model.width : size.model.depth

            if (widthModel < breakPoint) {
                // ww
            }
            else if (widthModel >= breakPoint && widthModel < breakPoint2) {
                ww = (ww - size.model.distance) / 2
            }
            else {
                ww = (ww - size.model.distance * 2) / 3
            }

            return ww
        }

        size.window.front = {
            width: getWindowWidth(true),
            height: size.window.height,
        }
        size.window.right = {
            width: getWindowWidth(false),
            height: size.window.height,
        }

    }, [size])

    // console.log('ATHEN')

    return (
        <group name="athen" ref={ref.athen} position={[-size.model.width/2, -size.model.height/2, -size.model.depth/2]}>
            <AthenWalls size={size} texture={texture}/>

            <mesh name="floor" position={[size.model.width/2, - 0.001, size.model.depth/2]} rotation-x={-Math.PI/2}>
                <Geometry useGroups>
                    <Base>
                        <planeGeometry args={[size.model.width - .0242, size.model.depth - .0242]} />
                        <meshStandardMaterial map={texture.floor}/>
                    </Base>
                    <Subtraction
                        position={[size.model.width / 2 + .07, - size.model.depth / 2 - .07, 0]}
                        rotation={[0, 0, Math.PI / 4]}
                        geometry={new THREE.PlaneGeometry()}
                    />
                </Geometry>
            </mesh>

            <AthenCeiling size={size} texture={texture} />

            <Bench size={ size }/>
            <Oven size={ size }/>
            <Lamp size={ size }/>
        </group>
    )
}