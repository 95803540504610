import {useContext, useEffect, useMemo, useRef, useState} from 'react'
import {OptionsContext} from '../../../App.js'
import TooltipButton from '../tooltip/TooltipButton.js'
import {configName, construction, dependenciesMaterial} from '../../../helpers/constants.js'
import './group.css'

export default function Item({ currentOption, selectedOption, configGroup }) {

    const { config } = useContext( OptionsContext )
    const { id, name, img, title, price, group } = selectedOption
    const { value: configGroupValue, setValue: setConfigGroupValue } = configGroup

    const [styleLabelIn, setStyleLabelIn] = useState({})

    const refImg = useRef()

    /** update height item content */
    useEffect(() => {
        function updateHeightLabel() {
            const heightImg = refImg.current.getBoundingClientRect().height
            setStyleLabelIn({
                minHeight: `calc(100% - ${heightImg + 'rem'})`,
                height: 'max-content',
            })
        }

        updateHeightLabel()

        window.addEventListener('resize', updateHeightLabel)
        return () => window.removeEventListener('resize', updateHeightLabel);
    }, [])

    function onOptionChange(event) {
        if (!(selectedOption._available && selectedOption.available)) return
        if (event.target.closest('.checkbox__tooltip_button')) return


        // TODO: Спробувати не використовувати функцію "setConfigGroupValue" а напряму змінити дані
        setConfigGroupValue(value => {

            // Select multiple options

            if ( configGroup.value.name === configName.lamp ) {

                if (selectedOption.id === 0) {
                    value.optionsSelected = [selectedOption]
                } else {

                    const existsIndex = value.optionsSelected.findIndex(o => o.id === selectedOption.id)

                    if (existsIndex < 0) {
                        const numberGroup = selectedOption.name.split('-')[1]
                        const filterResult = value.optionsSelected.filter(o => {
                            const group = o.name.split('-')[1]
                            return (!group || group !== numberGroup) && group !== 'not'
                        })
                        value.optionsSelected = [...filterResult, selectedOption]
                    } else {
                        value.optionsSelected.splice(existsIndex, 1)
                    }

                    if (!value.optionsSelected.length) {
                        value.optionsSelected.push( value.options[0] )
                    }
                }

            } else {

                // Single choice

                const indexArr = value.optionsSelected
                    .findIndex(o => o.name === currentOption.name && o.id === currentOption.id)

                value.optionsSelected.splice(indexArr, 1)
                value.optionsSelected.push(selectedOption)
                // console.log( value.optionsSelected.map(a => a.name ) )
            }

            return {...value}
        })
    }

    /**
     *  Логіка з Текстурами та Конструкціями і їх залежності
     *
     *
     *  OUT   Construction -> Type Wood
     */
    useMemo(() => {
        const dep = {}
        const depMaterial = config[configName.model].value.optionsSelected[0].name === 'morena'
            ? dependenciesMaterial.morena
            : dependenciesMaterial.venus
        const constructionName = config[configName.construction].value.optionsSelected[0].name
        const constructionId = construction.indexOf(constructionName)
        const typeWoodValue = config[configName.typeWood].value

        // Створити обєкт доступності елементів
        Object.keys(depMaterial).forEach(key => {
            dep[key] = depMaterial[key].out[constructionId]
        })

        // Скинути видимості і доступності
        typeWoodValue.options.forEach(option => {
            option.available = option.visible = true
        })

        // Встановити нові видимості і доступності
        typeWoodValue.options.forEach(option => {
            option.available = dep[option.name] > 1
            option.visible = dep[option.name] > 0
        })

        // Встановити вибраним елемент якщо попередній був прихований або недоступний
        const optionSelected = typeWoodValue.optionsSelected[0]
        if ( optionSelected && !(optionSelected.visible && optionSelected.available && optionSelected._available) ) {
            typeWoodValue.optionsSelected[0] = typeWoodValue.options.find(option => {
                return option.visible && option.available && option._available
            })
        }

    }, [config[configName.construction].value, config[configName.model].value])

    /**
     *  OUT  Type Wood -> Construction
     */
    useMemo(() => {
        const constructionValue = config[configName.construction].value

        if (config[configName.model].value.optionsSelected[0].name === 'morena') {
            const typeWoodValue = config[configName.typeWood].value

            // Створити обєкт доступності елементів
            const dep = dependenciesMaterial.morena[typeWoodValue.optionsSelected[0].name].out

            // Скинути  доступності
            constructionValue.options.forEach(option => {
                option.available = true
            })

            // Встановити нові доступності
            constructionValue.options.forEach(option => {
                const constructionId = construction.indexOf(option.name)
                option.available = dep[constructionId] > 1
            })

            // Встановити вибраним елемент якщо попередній був прихований або недоступний
            const optionSelected = constructionValue.optionsSelected[0]
            if ( ! (optionSelected.visible && optionSelected.available && optionSelected._available) ) {
                typeWoodValue.optionsSelected[0] = typeWoodValue.options.find(option => {
                    return option.visible && option.available && option._available
                })
            }
        } else {
            // Скинути видимості
            constructionValue.options.forEach(option => {
                option.available = true
            })
        }
    }, [config[configName.typeWood].value, config[configName.model].value])

    /**
     *  IN   Construction 2 -> Type Wood 2
     */
    useMemo(() => {
        const dep = {}
        const depMaterial = config[configName.model].value.optionsSelected[0].name === 'morena'
            ? dependenciesMaterial.morena
            : dependenciesMaterial.venus
        const constructionId = construction.indexOf(config[configName.construction2].value.optionsSelected[0].name)
        const typeWoodValue = config[configName.typeWood2].value

        // Створити обєкт доступності елементів
        Object.keys(depMaterial).forEach(key => {
            dep[key] = depMaterial[key].in[constructionId]
        })

        // Скинути видимості і доступності
        typeWoodValue.options.forEach(option => {
            option.available = option.visible = true
        })

        // Встановити нові видимості і доступності
        typeWoodValue.options.forEach(option => {
            option.available = dep[option.name] > 1
            option.visible = dep[option.name] > 0
        })

        // Встановити вибраним елемент якщо попередній був прихований або недоступний
        const optionSelected = typeWoodValue.optionsSelected[0]
        if ( optionSelected && !(optionSelected.visible && optionSelected.available && optionSelected._available) ) {
            typeWoodValue.optionsSelected[0] = typeWoodValue.options.find(option => {
                return option.visible && option.available && option._available
            })
        }
    }, [config[configName.construction2].value, config[configName.model].value])

    /**
     *  IN   Type Wood 2 -> Construction 2
     */
    useMemo(() => {
        const constructionValue = config[configName.construction2].value

        if (config[configName.model].value.optionsSelected[0].name === 'morena') {

            const typeWoodValue = config[configName.typeWood2].value

            // Створити обєкт доступності елементів
            const dep = dependenciesMaterial.morena[typeWoodValue.optionsSelected[0].name].in

            // Скинути видимості і доступності
            constructionValue.options.forEach(option => {
                option.available = option.visible = true
            })

            // Встановити нові видимості і доступності
            constructionValue.options.forEach(option => {
                const constructionId = construction.indexOf(option.name)
                option.available = dep[constructionId] > 1
                option.visible = dep[constructionId] > 0
            })

            // Встановити вибраним елемент якщо попередній був прихований або недоступний
            const optionSelected = constructionValue.optionsSelected[0]
            if ( ! (optionSelected.visible && optionSelected.available && optionSelected._available) ) {
                typeWoodValue.optionsSelected[0] = typeWoodValue.options.find(option => {
                    return option.visible && option.available && option._available
                })
            }

        } else {

            // Скинути видимості і доступності
            constructionValue.options.forEach(option => {
                option.available = option.visible = true
            })

        }




    }, [config[configName.typeWood2].value, config[configName.model].value])


    /**
     *  OUT & IN   Type Wood 1 + Type Wood 2 -> Construction Kassettenbauweise
     */
    useMemo(() => {
        const model = config[configName.model].value.optionsSelected[0].name !== 'morena'

        if (model) {
            const kassetten = config[configName.construction].value.optionsSelected[0].name === 'kassetten'
            const kassetten2 = config[configName.construction2].value.optionsSelected[0].name === 'kassetten'
            const typeWood = config[configName.typeWood].value.optionsSelected[0].name

            if (kassetten && kassetten2) {
                config[configName.typeWood2].value.optionsSelected[0] = config[configName.typeWood2].value.options
                    .find(option => {
                        return option.name === typeWood
                    })
            }
        }

    }, [
        config[configName.construction].value,
        config[configName.typeWood].value,
        config[configName.model].value,
    ])
    useMemo(() => {
        const model = config[configName.model].value.optionsSelected[0].name !== 'morena'

        if (model) {
            const kassetten = config[configName.construction].value.optionsSelected[0].name === 'kassetten'
            const kassetten2 = config[configName.construction2].value.optionsSelected[0].name === 'kassetten'
            const typeWood2 = config[configName.typeWood2].value.optionsSelected[0].name

            if (kassetten && kassetten2) {
                config[configName.typeWood].value.optionsSelected[0] = config[configName.typeWood].value.options
                    .find(option => {
                        return option.name === typeWood2
                    })
            }
        }

    }, [
        config[configName.construction2].value,
        config[configName.typeWood2].value,
        config[configName.model].value,
    ])


    /**
     *  1. Керувати доступністю нагрівальних приладів (пічки) для моделі "Морена"
     *  2. Якщо пічка не доступна знайти першу доступну в списку всіх опцій і поставити вибір на ній
     */
    useMemo(() => {
        const model = config[configName.model].value.optionsSelected[0]

        const notAvailable = [
            'picco_w',
            'thermotec_w',
            'euro_max_s',
            'bi_o_picco_w',
            'bi_o_tec_w',
            'bi_o_max'
        ]

        config[configName.ovenFinnish].value.options.forEach(oven => {
            if ( model.name === 'morena' ) {
                if ( notAvailable.includes(oven.name) ) {
                    oven.available = false
                }
            } else {
                oven.available = true
            }
        })

        config[configName.ovenBio].value.options.forEach(oven => {

            if ( model.name === 'morena' ) {
                if ( notAvailable.includes(oven.name) ) {
                    oven.available = false
                }
            } else {
                oven.available = true
            }
        })


        const finnSelected = config[configName.ovenFinnish].value.optionsSelected[0]
        if ( notAvailable.includes(finnSelected.name) ) {
            config[configName.ovenFinnish].value.optionsSelected[0] =
                config[configName.ovenFinnish].value.options.find(oven => oven.available && oven._available)
        }

        const bioSelected = config[configName.ovenBio].value.optionsSelected[0]
        if ( notAvailable.includes(bioSelected.name) ) {
            config[configName.ovenBio].value.optionsSelected[0] =
                config[configName.ovenBio].value.options.find(oven => oven.available && oven._available)
        }

    }, [config[configName.model].value])

    /**
     * Get css classes for the option element
     */
    function getClassNameCheckboxItem() {
        return `checkbox_item option_item_${currentOption.name} ${
            selectedOption._available && selectedOption.available ? 'checkbox_item--available' : ''
        } ${
            (configGroup.value.name === configName.lamp &&
                configGroupValue.optionsSelected.findIndex(o => o.name === name && o.id === id) >= 0)
            ||
            currentOption.name === name && currentOption.id === id
                ? 'checkbox_item--checked' : ''
        } ${
            selectedOption.visible ? '' : 'checkbox_item--hide'
        }`
    }

    return (
        <li className={getClassNameCheckboxItem()}>
            <div className="checkbox_item__in" onClick={ onOptionChange }>
                <div className="checkbox__element">
                    <div className="checkbox__label">
                        <div className="checkbox__img" ref={refImg}>
                            <img src={ img ? img : '/assets/images/no-image.svg' } alt=""/>
                        </div>
                        <div className="checkbox__label_in" style={ styleLabelIn }>
                            <span className="checkbox__title">{ title }</span>
                            {
                                price !== '' && (
                                    <span className="checkbox__price">
                                        <span>
                                            {group === configName.model
                                                ? [0, '0'].includes(price) ? price : `+${price}`
                                                : price
                                            }
                                        </span>
                                        &nbsp;
                                        <span>€</span>
                                    </span>
                                )

                            }
                        </div>
                    </div>
                </div>

                <TooltipButton item={ selectedOption } />
            </div>
        </li>
    )
}