import React, {useContext} from 'react'
import * as THREE from 'three'
import {useThree} from '@react-three/fiber'
import {OptionsContext} from '../../App.js'
import {configName} from '../../helpers/constants.js'

export default function Screenshot() {
    const { config, screenshot, ref } = useContext( OptionsContext )
    const tree = useThree()

    screenshot.create = callback => {
        const images = []
        const model = config[configName.model].value.optionsSelected[0]
        const ceiling = ref[model.name].current.children.find(elem => elem.name === 'ceiling')

        const { background: sceneBackground } =  tree.scene
        const { x: cameraPosX, y: cameraPosY, z: cameraPosZ } = ref.controls.current.object.position

        tree.scene.background = new THREE.Color('#ffffff')

        const positions = [
            // define camera positions of all sides as vector3, {x:0,y:0,z:0}
            {x: - 6, y: 2.5, z: 3.9},
            {x: - 6, y: 0, z: 0},
            {x: 0, y: 6, z: 0},
        ]

        const delayBetweenScreenshots = 0;

        const captureScreenshot = async (position, index) => {
            if (index === 2) ceiling.visible = false
            ref.controls.current.object.position.set(position.x, position.y, position.z);
            ref.controls.current?.update();
            await new Promise((resolve) => setTimeout(resolve, delayBetweenScreenshots));
            const screenshotDataUrl = ref.canvas.current.toDataURL('image/jpeg'); // image/png - default
            if (index === 2) ceiling.visible = true
            images.push(screenshotDataUrl)
            // const a = document.createElement('a');
            // a.href = screenshotDataUrl;
            // a.download = `${index}.jpg`;
            // a.click();
        };

        const captureScreenshotsSequentially = async () => {
            await new Promise((resolve) => setTimeout(resolve, 500));
            for (let i = 0; i < positions.length; i++) {
                await captureScreenshot(positions[i], i);
            }
        };

        captureScreenshotsSequentially().then(async () => {
            await new Promise((resolve) => setTimeout(resolve, delayBetweenScreenshots));
            ref.controls.current.object.position.set(cameraPosX, cameraPosY, cameraPosZ);
            tree.scene.background = sceneBackground
            ref.controls.current?.update();
            callback(images)
        })
    }

    return null
}