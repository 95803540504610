import {useContext, useMemo} from 'react'
import {OptionsContext} from '../../../App.js'

import Size from './Size.js'
import TooltipButton from '../tooltip/TooltipButton.js'
import {configName} from '../../../helpers/constants.js'
import './size.css'

export default function Sizes({ configGroup }) {

    const { config, priceTable } = useContext( OptionsContext )

    const minMaxSizes = useMemo(() => {
        const model = config[configName.model].value.optionsSelected[0]
        const type = config[configName.typeWood].value.optionsSelected[0]
        const typeWood = type.name === 'fichte' ? 'fichte' : type.name === 'zirbe' ? 'zirbe' : 'rest'
        const table = priceTable[ model.name ][ typeWood ]

        if ( !table ) return { width: { min: 1.3, max: 2.5 }, depth: { min: 1.3, max: 2.5 }}

        let depthMax
        let widthMax

        for (let i = table.length - 1; i >= 0; i--) {
            depthMax = table[i][0]
            if ( !isNaN(depthMax) && depthMax > 0) break
        }

        for (let i = table[0].length - 1; i >= 0; i--) {
            widthMax = table[0][i]
            if ( !isNaN(widthMax) && widthMax > 0) break
        }

        return {
            width: {
                min: Number( table[0][1] ) / 100,
                max: Number( widthMax ) / 100,
            },
            depth: {
                min: Number( table[1][0] ) / 100,
                max: Number( depthMax) / 100,
            }
        }

    }, [config[configName.model].value, config[configName.typeWood].value])

    return (
        <li className="options_panel__section size">
            <div className="options_panel__header">
                <h2 className="title">Sauna Außenmaße</h2>
                <TooltipButton item={ configGroup.value } />
            </div>
            <Size option={configGroup.value.options[0]} minMax={minMaxSizes.width}/>
            <Size option={configGroup.value.options[1]} minMax={minMaxSizes.depth}/>
        </li>
    )
}
