import React, {useContext} from 'react'
import {OptionsContext} from '../../App.js'

import Walls from '../elements/Walls.js'
import Ceiling from '../elements/Ceiling.js'
import Bench from '../bench/Bench.js'
import Oven from '../oven/Oven.js'
import Lamp from '../lamp/Lamp.js'

export default function Venus({ size, texture }) {
    const { ref } = useContext( OptionsContext )

    // console.log('VENUS')

    return (
        <group name="venus" ref={ref.venus} position={[-size.model.width/2, -size.model.height/2, -size.model.depth/2]}>
            <Walls size={size} texture={texture}/>

            <mesh name="floor"
                  position={[size.model.width/2, 0.001, size.model.depth/2]}
                  rotation-x={-Math.PI/2}>
                <planeGeometry args={[size.model.width, size.model.depth]} />
                <meshStandardMaterial map={texture.floor}/>
            </mesh>

            <Ceiling size={size} texture={texture}/>

            <Bench size={ size }/>
            <Oven size={ size }/>
            <Lamp size={ size }/>
        </group>
    )
}