/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 Prestige.glb 
*/

import React, {useContext} from 'react'
import {useGLTF} from '@react-three/drei'
import {OptionsContext} from '../../App.js'

export function Prestige({material, ...props}) {
    const {nodes} = useGLTF('/assets/models/bench/Prestige.glb')
    const {nodes: nodesNew} = useGLTF('/assets/models/bench/Prestige_new.glb')
    const {ref} = useContext(OptionsContext)

    return (
        <group {...props} dispose={null} ref={ref.bench.prestige.set} name="Prestige" visible={false}>
            <group ref={ref.bench.prestige.c} visible={false}>
                <group ref={ref.bench.prestige.c1}>
                    <group>
                        <mesh geometry={nodesNew.Cube020.geometry} material={material} position={[1.085, 0.992, 0.03]}
                              rotation={[-0.316, 0, 0]}/>
                        <mesh geometry={nodesNew.Cube095.geometry} material={material} position={[1.085, 0.751, 0.104]}
                              rotation={[-0.275, 0, 0]}/>
                        <mesh geometry={nodesNew.Cube096.geometry} material={material} position={[1.085, 0.852, 0.075]}
                              rotation={[-0.281, 0, 0]}/>
                        <mesh geometry={nodesNew.Cube061.geometry} material={material}
                              position={[0.255, 0.812, 0.028]}/>
                        <mesh geometry={nodesNew.Cube062.geometry} material={material}
                              position={[0.996, 0.812, 0.028]}/>
                        <mesh geometry={nodesNew.Cube097.geometry} material={material}
                              position={[1.737, 0.812, 0.028]}/>
                    </group>

                    <group>
                        <mesh geometry={nodesNew.Cube098.geometry} material={material} position={[0.915, 0.992, 0.03]}
                              rotation={[-0.316, 0, 0]}/>
                        <mesh geometry={nodesNew.Cube104.geometry} material={material} position={[0.915, 0.751, 0.104]}
                              rotation={[-0.275, 0, 0]}/>
                        <mesh geometry={nodesNew.Cube105.geometry} material={material} position={[0.915, 0.852, 0.075]}
                              rotation={[-0.281, 0, 0]}/>
                        <mesh geometry={nodesNew.Cube061.geometry} material={material}
                              position={[0.255, 0.812, 0.028]}/>
                        <mesh geometry={nodesNew.Cube062.geometry} material={material}
                              position={[0.996, 0.812, 0.028]}/>
                        <mesh geometry={nodesNew.Cube097.geometry} material={material}
                              position={[1.737, 0.812, 0.028]}/>
                    </group>

                    <group>
                        <mesh geometry={nodes.Cube048.geometry} material={material}/>
                        <mesh geometry={nodes.Cube047.geometry} material={material}/>
                        <mesh geometry={nodes.Cube046.geometry} material={material}/>
                        <mesh geometry={nodes.Cube045.geometry} material={material}/>
                        <mesh geometry={nodes.Cube044.geometry} material={material}/>
                        <mesh geometry={nodes.Cube043.geometry} material={material}/>

                        <mesh geometry={nodes.Cube042.geometry} material={material}/>
                        <mesh geometry={nodes.Cube049.geometry} material={material}/>
                        <mesh geometry={nodes.Cube050.geometry} material={material}/>
                        <mesh geometry={nodes.Cube051.geometry} material={material}/>
                        <mesh geometry={nodes.Cube057.geometry} material={material}/>
                        <mesh geometry={nodes.Cube058.geometry} material={material}/>
                        <mesh geometry={nodes.Cube059.geometry} material={material}/>
                        <mesh geometry={nodes.Cube060.geometry} material={material}/>

                        <mesh geometry={nodes.Cube038.geometry} material={material}/>
                        <mesh geometry={nodes.Cube037.geometry} material={material}/>
                        <mesh geometry={nodes.Cube036.geometry} material={material}/>
                        <mesh geometry={nodes.Cube035.geometry} material={material}/>
                        <mesh geometry={nodes.Cube034.geometry} material={material}/>
                    </group>
                </group>

                <group ref={ref.bench.prestige.c2}>
                    <group>
                        <mesh geometry={nodesNew.Cube019.geometry} material={material} position={[0.03, 0.992, 1.092]}
                              rotation={[-Math.PI / 2, 1.255, Math.PI / 2]}/>
                        <mesh geometry={nodesNew.Cube024.geometry} material={material} position={[0.104, 0.751, 1.092]}
                              rotation={[-Math.PI / 2, 1.295, Math.PI / 2]}/>
                        <mesh geometry={nodesNew.Cube025.geometry} material={material} position={[0.075, 0.852, 1.092]}
                              rotation={[-Math.PI / 2, 1.29, Math.PI / 2]}/>
                        <mesh geometry={nodesNew.Cube056.geometry} material={material} position={[.028, 0.812, 0.28]}/>
                        <mesh geometry={nodesNew.Cube056.geometry} material={material} position={[.028, 0.812, 1.1]}/>
                        <mesh geometry={nodesNew.Cube056.geometry} material={material} position={[.028, 0.812, 1.9]}/>
                    </group>

                    <group>
                        <mesh geometry={nodes.Cube014.geometry} material={material}/>
                        <mesh geometry={nodes.Cube013.geometry} material={material}/>
                        <mesh geometry={nodes.Cube008.geometry} material={material}/>
                        <mesh geometry={nodes.Cube007.geometry} material={material}/>
                        <mesh geometry={nodes.Cube002.geometry} material={material}/>
                        <mesh geometry={nodes.Cube001.geometry} material={material}/>
                    </group>
                </group>

                <group ref={ref.bench.prestige.c3}>
                    <group>
                        <mesh geometry={nodesNew.Cube017.geometry} material={material} position={[1.97, 0.992, 1.092]}
                              rotation={[-Math.PI / 2, -1.255, -Math.PI / 2]}/>
                        <mesh geometry={nodesNew.Cube040.geometry} material={material} position={[1.896, 0.751, 1.092]}
                              rotation={[-Math.PI / 2, -1.295, -Math.PI / 2]}/>
                        <mesh geometry={nodesNew.Cube055.geometry} material={material} position={[1.925, 0.852, 1.092]}
                              rotation={[-Math.PI / 2, -1.29, -Math.PI / 2]}/>
                        <mesh geometry={nodesNew.Cube015.geometry} material={material} position={[1.971, 0.812, 0.28]}/>
                        <mesh geometry={nodesNew.Cube015.geometry} material={material} position={[1.971, 0.812, 1.1]}/>
                        <mesh geometry={nodesNew.Cube015.geometry} material={material} position={[1.971, 0.812, 1.9]}/>
                    </group>

                    <group>
                        <mesh geometry={nodes.Cube033.geometry} material={material}/>
                        <mesh geometry={nodes.Cube032.geometry} material={material}/>
                        <mesh geometry={nodes.Cube031.geometry} material={material}/>
                        <mesh geometry={nodes.Cube030.geometry} material={material}/>
                        <mesh geometry={nodes.Cube029.geometry} material={material}/>
                        <mesh geometry={nodes.Cube028.geometry} material={material}/>
                    </group>
                </group>
            </group>

            <group ref={ref.bench.prestige.b}>
                <group>
                    <mesh geometry={nodesNew.Cube087.geometry} material={material} position={[1, 0.852, 0.075]}
                          rotation={[-0.281, 0, 0]}/>
                    <mesh geometry={nodesNew.Cube088.geometry} material={material} position={[1, 0.751, 0.104]}
                          rotation={[-0.275, 0, 0]}/>
                    <mesh geometry={nodesNew.Cube099.geometry} material={material} position={[1, 0.992, 0.03]}
                          rotation={[-0.316, 0, 0]}/>
                    <mesh geometry={nodesNew.Cube061.geometry} material={material} position={[0.255, 0.812, 0.028]}/>
                    <mesh geometry={nodesNew.Cube062.geometry} material={material} position={[0.996, 0.812, 0.028]}/>
                    <mesh geometry={nodesNew.Cube097.geometry} material={material} position={[1.737, 0.812, 0.028]}/>
                </group>

                <mesh geometry={nodes.Cube048.geometry} material={material}/>
                <mesh geometry={nodes.Cube047.geometry} material={material}/>
                <mesh geometry={nodes.Cube046.geometry} material={material}/>
                <mesh geometry={nodes.Cube045.geometry} material={material}/>
                <mesh geometry={nodes.Cube044.geometry} material={material}/>
                <mesh geometry={nodes.Cube043.geometry} material={material}/>

                <mesh geometry={nodes.Cube042.geometry} material={material}/>
                <mesh geometry={nodes.Cube049.geometry} material={material}/>
                <mesh geometry={nodes.Cube050.geometry} material={material}/>
                <mesh geometry={nodes.Cube051.geometry} material={material}/>
                <mesh geometry={nodes.Cube057.geometry} material={material}/>
                <mesh geometry={nodes.Cube058.geometry} material={material}/>
                <mesh geometry={nodes.Cube059.geometry} material={material}/>
                <mesh geometry={nodes.Cube060.geometry} material={material}/>

                <mesh geometry={nodes.Cube038.geometry} material={material}/>
                <mesh geometry={nodes.Cube037.geometry} material={material}/>
                <mesh geometry={nodes.Cube036.geometry} material={material}/>
                <mesh geometry={nodes.Cube035.geometry} material={material}/>
                <mesh geometry={nodes.Cube034.geometry} material={material}/>
            </group>

            <group ref={ref.bench.prestige.d}>
                <group ref={ref.bench.prestige.d1}>
                    <mesh geometry={nodesNew.Cube082.geometry} material={material} position={[0.075, 0.852, 0.999]}
                          rotation={[-Math.PI / 2, 1.29, Math.PI / 2]}/>
                    <mesh geometry={nodesNew.Cube083.geometry} material={material} position={[0.104, 0.751, 0.999]}
                          rotation={[-Math.PI / 2, 1.295, Math.PI / 2]}/>
                    <mesh geometry={nodesNew.Cube084.geometry} material={material} position={[0.03, 0.992, 0.999]}
                          rotation={[-Math.PI / 2, 1.255, Math.PI / 2]}/>
                    <mesh geometry={nodesNew.Cube056.geometry} material={material} position={[.028, 0.812, 0.09]}/>
                    <mesh geometry={nodesNew.Cube056.geometry} material={material} position={[.028, 0.812, 1]}/>
                    <mesh geometry={nodesNew.Cube056.geometry} material={material} position={[.028, 0.812, 1.9]}/>

                    <mesh geometry={nodes.Cube009.geometry} material={material}/>
                    <mesh geometry={nodes.Cube010.geometry} material={material}/>
                    <mesh geometry={nodes.Cube011.geometry} material={material}/>
                    <mesh geometry={nodes.Cube012.geometry} material={material}/>
                    <mesh geometry={nodes.Cube016.geometry} material={material}/>
                    <mesh geometry={nodes.Cube018.geometry} material={material}/>
                </group>

                <group ref={ref.bench.prestige.d2}>
                    <mesh geometry={nodesNew.Cube078.geometry} material={material} position={[1.925, 0.852, 0.999]}
                          rotation={[-Math.PI / 2, -1.29, -Math.PI / 2]}/>
                    <mesh geometry={nodesNew.Cube080.geometry} material={material} position={[1.896, 0.751, 0.999]}
                          rotation={[-Math.PI / 2, -1.295, -Math.PI / 2]}/>
                    <mesh geometry={nodesNew.Cube085.geometry} material={material} position={[1.97, 0.992, 0.999]}
                          rotation={[-Math.PI / 2, -1.255, -Math.PI / 2]}/>
                    <mesh geometry={nodesNew.Cube015.geometry} material={material} position={[1.971, 0.812, 0.09]}/>
                    <mesh geometry={nodesNew.Cube015.geometry} material={material} position={[1.971, 0.812, 1]}/>
                    <mesh geometry={nodesNew.Cube015.geometry} material={material} position={[1.971, 0.812, 1.9]}/>


                    <mesh geometry={nodes.Cube021.geometry} material={material}/>
                    <mesh geometry={nodes.Cube022.geometry} material={material}/>
                    <mesh geometry={nodes.Cube023.geometry} material={material}/>
                    <mesh geometry={nodes.Cube052.geometry} material={material}/>
                    <mesh geometry={nodes.Cube053.geometry} material={material}/>
                    <mesh geometry={nodes.Cube054.geometry} material={material}/>
                </group>

                <group ref={ref.bench.prestige.d3} position={[.001, -.002, 0]}>
                    <mesh geometry={nodes.Cube038.geometry} material={material} position={[0, 0, -.0908]}/>
                    <mesh geometry={nodes.Cube038.geometry} material={material}/>
                    <mesh geometry={nodes.Cube037.geometry} material={material}/>
                    <mesh geometry={nodes.Cube036.geometry} material={material}/>
                    <mesh geometry={nodes.Cube035.geometry} material={material}/>
                    <mesh geometry={nodes.Cube034.geometry} material={material}/>
                </group>
            </group>
        </group>
    )
}

useGLTF.preload('/assets/models/bench/Prestige.glb')
useGLTF.preload('/assets/models/bench/Prestige_new.glb')
