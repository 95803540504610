export const configName = {
    model: 'model',
    size: 'size',

    typeWood: 'type_wood',
    typeWood2: 'type_wood_in',
    construction: 'construction_out', // out cassette design
    construction2: 'construction_in',             // in cassette design

    roomWalls: 'raumwande',

    glassElem: 'glaselemente',
    glassType: 'glastyp',

    fittings: 'turbeschlage',
    doorHandles: 'turgriffe',

    lamp: 'beleuchtung',
    typeWoodBench: 'type_wood_bench',
    benchOption: 'bench_option',
    bankaufstellung: 'bankaufstellung',
    benchLayout: 'bench_layout',


    accesories: 'eimer',
    bankType: 'banktyp',
    frontOfTheBank: 'vorderseite_der_bank',

    oven: 'ofen',
    ovenFinnish: 'finnischer_ofen',
    ovenBio: 'bio_ofen',

    steering: 'steuerung',
    additionalModule: 'zusatzmodul',
    irEmitter: 'ir_strahler',
    bucket: 'eimer',
    thermometer: 'thermometer',
    other: 'andere',
}

export const additionalOptionName = {
    selectPage: 'Seite auswahlen',
    left: 'links',
    right: 'rechts'
}

export const modelHeight = 2

export const construction = ['kassetten', 'platte', 'profilholz', 'stabchen']
// 0 - відсутній
// 1 - присутній але недоступний
// 2 - присутній і доступний
export const dependenciesMaterial = {
    venus: {
        fichte: {out: [2,0,0,0], in: [2,0,0]},
        zirbe:  {out: [2,2,2,2], in: [2,2,2]},
    },
    morena: {
        fichte:        {out: [0,0,0,0], in: [2,2,2]},
        zirbe:         {out: [2,2,2,2], in: [2,2,2]},
        erle:          {out: [2,2,2,2], in: [1,2,2]},
        espe:          {out: [1,2,2,2], in: [1,2,1]},
        termo_erle:    {out: [1,2,2,2], in: [1,2,1]},
        termo_espe:    {out: [1,2,1,1], in: [1,2,2]},
        eiche_rissig:  {out: [1,2,2,1], in: [1,2,1]},
        eiche_altholz: {out: [1,2,1,1], in: [1,2,1]},
        hemlock:       {out: [1,2,1,1], in: [1,2,1]},
        structur1:     {out: [1,2,1,1], in: [1,2,1]},
        structur2:     {out: [1,2,1,1], in: [1,2,1]},
        structur3:     {out: [1,2,1,1], in: [1,2,1]},
    },
}
