/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 Doppelturband_B3305_C04.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF('/assets/models/fittings/Doppelturband_B3305_C04.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[-0.015, 0.332, 0.092]} scale={[0.144, 0.287, 0.144]}>
        <mesh geometry={nodes.Cylinder001_1.geometry} material={materials.Material} />
        <mesh geometry={nodes.Cylinder001_2.geometry} material={materials['Material.005']} />
      </group>
      <mesh geometry={nodes.Cylinder002.geometry} material={materials.Material} position={[-0.015, 1.239, 0.089]} scale={[0.144, 0.61, 0.144]} />
      <group position={[0.089, 1.237, 0.084]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={-1.202}>
        <mesh geometry={nodes.Circle004.geometry} material={materials.Material} />
        <mesh geometry={nodes.Circle004_1.geometry} material={materials.Material} />
      </group>
      <mesh geometry={nodes.Circle007.geometry} material={materials['Material.004']} position={[-0.2, 1.212, 0.956]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1.083, 0.668, 1.083]} />
      <mesh geometry={nodes.Circle008.geometry} material={materials['Material.004']} position={[-0.13, 1.212, 0.956]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1.083, 0.668, 1.083]} />
      <group position={[-0.01, 1.237, -0.028]} rotation={[Math.PI / 2, 0, 0]} scale={1.202}>
        <mesh geometry={nodes.Circle003.geometry} material={materials.Material} />
        <mesh geometry={nodes.Circle003_1.geometry} material={materials.Material} />
      </group>
      <mesh geometry={nodes.Circle010.geometry} material={materials.Material} position={[-0.732, 1.243, 0.313]} rotation={[-Math.PI / 2, 0, Math.PI]} scale={[-1.644, -1.499, -1.234]} />
      <mesh geometry={nodes.Circle011.geometry} material={materials['Material.004']} position={[-0.876, 1.212, 0.256]} rotation={[-Math.PI / 2, 0, Math.PI]} scale={[-1.083, -0.66, -1.083]} />
      <mesh geometry={nodes.Circle012.geometry} material={materials['Material.004']} position={[-0.876, 1.212, 0.185]} rotation={[-Math.PI / 2, 0, Math.PI]} scale={[-1.083, -0.66, -1.083]} />
      <mesh geometry={nodes.Circle006.geometry} material={materials.Material} position={[-0.272, 1.243, 0.803]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[-1.613, -1.499, -1.234]} />
      <mesh geometry={nodes.Cylinder004.geometry} material={materials['Material.003']} position={[-0.014, 1.856, 0.089]} scale={[0.144, 0.005, 0.144]} />
      <mesh geometry={nodes.Cylinder005.geometry} material={materials['Material.003']} position={[-0.014, 0.624, 0.089]} scale={[0.144, 0.005, 0.144]} />
      <group position={[-0.015, 2.147, 0.092]} rotation={[-Math.PI, 0, 0]} scale={[0.144, 0.287, 0.144]}>
        <mesh geometry={nodes.Cylinder006.geometry} material={materials.Material} />
        <mesh geometry={nodes.Cylinder006_1.geometry} material={materials['Material.005']} />
      </group>
      <group position={[-0.25, 1.778, 0.774]} rotation={[Math.PI / 2, 0, 1.583]} scale={[49.639, 30.124, 49.641]}>
        <mesh geometry={nodes.Bolt003_1.geometry} material={materials['Iron Touched']} />
        <mesh geometry={nodes.Bolt003_2.geometry} material={materials['Material.006']} />
      </group>
      <group position={[-0.25, 0.7, 0.774]} rotation={[2.361, 0, 1.583]} scale={[49.406, 29.983, 49.409]}>
        <mesh geometry={nodes.Bolt001_1.geometry} material={materials['Iron Touched']} />
        <mesh geometry={nodes.Bolt001_2.geometry} material={materials['Material.006']} />
      </group>
      <group position={[-0.647, 1.75, 0.33]} rotation={[Math.PI / 2, 0, 0.012]} scale={[49.634, 9.324, 49.641]}>
        <mesh geometry={nodes.Bolt002_1.geometry} material={materials['Iron Touched']} />
        <mesh geometry={nodes.Bolt002_2.geometry} material={materials['Material.006']} />
      </group>
      <group position={[-0.647, 0.823, 0.33]} rotation={[Math.PI / 2, 0, 0.012]} scale={[49.634, 9.324, 49.641]}>
        <mesh geometry={nodes.Bolt005_1.geometry} material={materials['Iron Touched']} />
        <mesh geometry={nodes.Bolt005_2.geometry} material={materials['Material.006']} />
      </group>
      <mesh geometry={nodes.Bolt004.geometry} material={materials['Iron Touched']} position={[-0.072, 1.778, 0.774]} rotation={[Math.PI / 2, 0, 1.583]} scale={[49.663, 84.741, 49.641]} />
      <mesh geometry={nodes.Bolt006.geometry} material={materials['Iron Touched']} position={[-0.109, 0.7, 0.774]} rotation={[2.361, 0, 1.583]} scale={[49.419, 70.609, 49.409]} />
      <mesh geometry={nodes.Bolt007.geometry} material={materials['Iron Touched']} position={[-0.647, 1.75, 0.136]} rotation={[Math.PI / 2, 0, 0.012]} scale={[49.741, 87.335, 49.641]} />
      <mesh geometry={nodes.Bolt008.geometry} material={materials['Iron Touched']} position={[-0.647, 0.823, 0.136]} rotation={[Math.PI / 2, 0, 0.012]} scale={[49.741, 87.335, 49.641]} />
    </group>
  )
}

// useGLTF.preload('/assets/models/fittings/Doppelturband_B3305_C04.glb')
