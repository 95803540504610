/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 8180_GW_open_mirror.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF('/assets/models/fittings/8180_GW_open_mirror.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Plane001.geometry} material={materials['Material.003']} position={[0.347, 1.048, 0.871]} rotation={[0, 0, -Math.PI / 2]} scale={[1, 1, 0.39]} />
      <mesh geometry={nodes.Plane.geometry} material={materials['Material.003']} position={[1.114, 1.028, 0.964]} rotation={[-Math.PI / 2, Math.PI / 2, 0]} />
      <mesh geometry={nodes.Cylinder.geometry} material={materials['Material.003']} position={[0.449, 1.01, 0.843]} scale={[0.194, 0.095, 0.153]} />
      <mesh geometry={nodes.Plane003.geometry} material={materials['Material.002']} position={[-0.436, 1.039, 0.862]} rotation={[Math.PI / 2, 0, Math.PI]} scale={0.951} />
      <mesh geometry={nodes.Plane004.geometry} material={materials['Material.002']} position={[1.113, 1.039, 0.846]} rotation={[Math.PI / 2, 0, 0]} scale={0.951} />
      <mesh geometry={nodes.Plane005.geometry} material={materials['Material.003']} position={[0.151, 1.047, 0]} rotation={[0, 0, -Math.PI / 2]} />
    </group>
  )
}

// useGLTF.preload('/assets/models/fittings/8180_GW_open_mirror.glb')
