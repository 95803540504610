import {useRef} from 'react'

export default function Light() {
    const dirLight = useRef()
    // const pointLight = useRef()

    // TODO: Debug
    // useHelper(dirLight, DirectionalLightHelper, .5, 'red')
    // useHelper(pointLight, PointLightHelper, .2, 'yellowgreen')

    const colorLight = '#fff6e5'
    const intensityLight = 1

    return (
        <group>
            <ambientLight intensity={1} />
            <group position={[0, 0, 0]}>
                <directionalLight position={ [- 2, 2, 3] } color={ colorLight } intensity={intensityLight} ref={dirLight} />
                <directionalLight position={ [- 2, 2, - 3] } color={ colorLight } intensity={intensityLight} />
                <directionalLight position={ [- 3, 2, 0] } color={ colorLight } intensity={intensityLight} />
                <directionalLight position={ [1.5, - 2, 0] } color={ colorLight } intensity={intensityLight} />
            </group>
        </group>
    )
}
