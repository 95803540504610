import React, {useContext} from 'react'
import {Color} from 'three'
import {Line, Cone, Text} from '@react-three/drei'
import {OptionsContext} from '../../App.js'

export function Dimensions({ size }) {
    const { ref } = useContext( OptionsContext )

    const fontSize = .15
    const colorLine = '#a2a2a2'
    const colorNumber = '#414141'
    const offsetOutgoingLine = .05
    const lengthOutgoingLine = .8
    const offsetDimensionalLine = lengthOutgoingLine * 5 / 6

    return (
        <group>

            <group name="width" position={[-size.model.width/2, -size.model.height/2, size.model.depth/2 + offsetOutgoingLine]}>
                <Line points={[[0, 0, 0], [0, 0, lengthOutgoingLine]]} color={colorLine} />

                <group position={[0, 0, offsetDimensionalLine]}>
                    <ConeApp color={colorLine} rotation-z={Math.PI/2} position-x={.05} />
                    <ConeApp color={colorLine} rotation-z={- Math.PI/2} position-x={size.model.width - .05} />

                    <Line points={[[0, 0, 0], [size.model.width / 3, 0, 0]]} color={colorLine}/>
                    <Line points={[[size.model.width / 3 * 2, 0, 0], [size.model.width, 0, 0]]} color={colorLine}/>

                    <Text
                        fontSize={fontSize}
                        color={new Color(colorNumber)}
                        position={[size.model.width/2, 0, 0]}
                        rotation-x={- Math.PI / 2}
                    >
                        { (size.model.width * 100).toFixed(0) + " cm" }
                        <meshStandardMaterial />
                    </Text>
                    <Text
                        fontSize={ .15 }
                        color={new Color(colorNumber)}
                        position={[size.model.width/2, 0, 0]}
                        rotation-x={Math.PI / 2}
                    >
                        { (size.model.width * 100).toFixed(0) + " cm" }
                        <meshStandardMaterial />
                    </Text>


                </group>

                <Line points={[[size.model.width, 0, 0], [size.model.width, 0, lengthOutgoingLine]]} color={colorLine}/>
            </group>


            <group name="depth" position={[size.model.width/2 + offsetOutgoingLine, -size.model.height/2, -size.model.depth/2]}>
                <Line points={[[0, 0, 0], [lengthOutgoingLine, 0, 0]]} color={colorLine}/>

                <group position={[offsetDimensionalLine, 0, 0]}>
                    <ConeApp color={colorLine} rotation-x={- Math.PI/2} position-z={.05} />
                    <ConeApp color={colorLine} rotation-x={Math.PI/2} position-z={size.model.depth - .05} />

                    <Line points={[[0, 0, 0], [0, 0, size.model.depth / 3]]} color={colorLine}/>
                    <Line points={[[0, 0, size.model.depth/3*2], [0, 0, size.model.depth]]} color={colorLine}/>

                    <Text
                        fontSize={fontSize}
                        color={new Color(colorNumber)}
                        position={[0, 0, size.model.depth / 2]}
                        rotation-x={- Math.PI / 2}
                        rotation-z={Math.PI / 2}
                    >
                        { (size.model.depth * 100).toFixed(0) + " cm" }
                        <meshStandardMaterial />
                    </Text>
                    <Text
                        fontSize={fontSize}
                        color={new Color(colorNumber)}
                        position={[0, 0, size.model.depth / 2]}
                        rotation-x={Math.PI / 2}
                        rotation-z={-Math.PI / 2}
                    >
                        { (size.model.depth * 100).toFixed(0) + " cm" }
                        <meshStandardMaterial />
                    </Text>

                </group>

                <Line points={[[0, 0, size.model.depth], [lengthOutgoingLine, 0, size.model.depth]]} color={colorLine}/>
            </group>


            <group ref={ref.dimensionsHeight} name="height" position={[-size.model.width/2 - offsetOutgoingLine, -size.model.height/2, size.model.depth/2]}>
                <Line points={[[0, 0, 0], [-lengthOutgoingLine, 0, 0]]} color={colorLine}/>

                <group position={[- offsetDimensionalLine, 0, 0]}>
                    <ConeApp color={colorLine} position-y={.05} rotation-z={Math.PI} />
                    <ConeApp color={colorLine} position-y={size.model.height - .05} />

                    <Line points={[[0, 0, 0], [0, size.model.height / 3, 0]]} color={colorLine}/>
                    <Line points={[[0, size.model.height / 3 * 2, 0], [0, size.model.height, 0]]} color={colorLine}/>

                    <Text
                        fontSize={fontSize}
                        color={new Color(colorNumber)}
                        position={[0, size.model.height / 2, 0]}
                        rotation-z={Math.PI / 2}
                    >
                        { (size.model.height * 100).toFixed(0) + " cm" }
                        <meshStandardMaterial />
                    </Text>
                    <Text
                        fontSize={fontSize}
                        color={new Color(colorNumber)}
                        position={[0, size.model.height / 2, 0]}
                        rotation-z={Math.PI / 2}
                        rotation-y={Math.PI}
                    >
                        { (size.model.height * 100).toFixed(0) + " cm" }
                        <meshStandardMaterial />
                    </Text>

                </group>

                <Line points={[[0, size.model.height, 0], [-lengthOutgoingLine, size.model.height, 0]]} color={colorLine}/>
            </group>

        </group>
    )
}

function ConeApp({ color, ...props}) {
    return <Cone args={[.018, .1, 4]} {...props}><meshStandardMaterial color={color} /></Cone>
}