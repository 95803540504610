/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.18 Griff_Holz.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Rund_Holz(props) {
  const { nodes, materials } = useGLTF('/assets/models/doorHandle/Rund_Holz.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cylinder001.geometry} material={materials['Material.001']} position={[0.819, 0, 1.832]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.25, 1.093, 0.25]} />
      <mesh geometry={nodes.Cylinder002.geometry} material={materials['Material.001']} position={[0.777, 0.001, -1.831]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.25, 1.093, 0.25]} />
      <mesh geometry={nodes.bolt_head.geometry} material={materials['Iron Touched']} position={[-0.107, -0.002, 1.835]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={70.626} />
      <mesh geometry={nodes.Cylinder_1.geometry} material={materials['Material.001']} />
      <mesh geometry={nodes.Cylinder_2.geometry} material={materials['Material.001']} />
    </group>
  )
}

useGLTF.preload('/assets/models/doorHandle/Rund_Holz.glb')
