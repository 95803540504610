import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'
import {OptionsContext} from '../../App.js'
import {configName} from '../../helpers/constants.js'
import {useGLTF} from '@react-three/drei'

useGLTF.preload('/assets/models/bench/textures/Textures.glb')
import {Standart} from './Standart.jsx'
import {Exclusiv} from './Exclusiv.jsx'
import {Prestige} from './Prestige.jsx'
import {Comfort} from './Comfort.jsx'
import {Luxus} from './Luxus.jsx'
import {Hocker_massiv} from './Hocker_massiv.jsx'

const decorations = ['standard', 'prestige', 'exclusive', 'lux', 'comfort']
const layouts = ['c', 'b', 'c_mirrored', 'd']
const meshGroups = ['set', 'c', 'c1', 'c2', 'c3', 'b', 'd', 'd1', 'd2', 'd3']
const benchInitSize = { width: 2, depth: 2 }
const stoolInitSize = { width: .6, depth: .36 }

export default function Bench({ size }) {
    const { config, ref } = useContext( OptionsContext )

    /** Refs */
    decorations.forEach(decoration => meshGroups.forEach(layout =>  ref.bench[decoration][layout] = useRef()))
    ref.bench.wrapper = useRef()
    ref.bench.stool = useRef()

    /** State */
    const [layout, setLayout] = useState('c')
    const [decoration, setDecoration] = useState('standard')

    // layout - C, B, C_Mirrored, D (Bankaufstellung - Макет)
    useEffect(() => {
        const id = config[configName.benchLayout].value.optionsSelected[0].id
        const layout = layouts[id]
        setLayout(layout)
    }, [config[configName.benchLayout].value])

    // decoration - standard, prestige, exclusive, lux, comfort (Inneneinrichtung - Внутрішнє оздоблення)
    useEffect(() => {
        setDecoration( decorations[config[configName.benchOption].value.optionsSelected[0].id] )
    }, [config[configName.benchOption].value])

    /** Materials */
    const { materials } = useGLTF('/assets/models/bench/textures/Textures.glb')
    const material = useMemo(() => {
        // Type Wood - Espenholz, Zirbenholz, Erlenholz
        switch (config[configName.typeWoodBench].value.optionsSelected[0].id) {
            case 0: return materials.Espenholz
            case 1: return materials.Zirbe
            case 2: return materials.NewErlenHolz
        }
    }, [config[configName.typeWoodBench].value])

    const model = useMemo(() => {
        return config[configName.model].value.optionsSelected[0]
    }, [config[configName.model].value])

    /** Resizing behavior */
    useEffect(() => {
        const {width: widthOut, depth: depthOut} = config[configName.size].value
        const width = widthOut - size.model.wallThickness * 2
        const depth = depthOut - size.model.wallThickness * 2
        const sideGlassRight = size.glassElem.side === 'R'

        decorations.forEach(dec => {
            if (ref.bench[dec].set.current) {
                ref.bench[dec].set.current.visible = dec === decoration
            }
        })

        /* Init (size, position) */
        ref.bench.wrapper.current.position.set(size.model.wallThickness, .28, size.model.wallThickness)
        ref.bench.wrapper.current.rotation.y = 0
        ref.bench[decoration].b.current.visible = layout === 'b'
        ref.bench[decoration].c.current.visible = layout === 'c' || layout === 'c_mirrored'
        ref.bench[decoration].d.current.visible = layout === 'd'
        ref.bench.stool.current.visible = layout === 'b'

        const sizeOkLayoutC = widthOut >= 1.8 && depthOut >= 1.8
        const sizeOkLayoutD = widthOut >= 2.1 && depthOut >= 2.1

        config[configName.benchLayout].setValue(value => {
            value.options.find(o => o.name === 'layout_d').available = sizeOkLayoutD
            value.options.find(o => o.name === 'layout_c').available = sizeOkLayoutC
            value.options.find(o => o.name === 'layout_c_mirrored').available = sizeOkLayoutC
            return {...value}
        })

        const resetBench = () => {
            config[configName.benchLayout].setValue(value => {
                value.optionsSelected = [value.options[value.options.length - 1]]
                return {...value}
            })

            ref.info3d.current.querySelector('.text').innerText = 'Diese Anordung der Bänke ist bei solchen Maßen leider nicht möglich'
            ref.info3d.current.classList.remove('hide')

            setTimeout(() => {
                ref.info3d.current.querySelector('.text').innerText = ''
                ref.info3d.current.classList.add('hide')
            }, 3000)
        }


        /* Layout C */
        if (layout === 'c' || layout === 'c_mirrored') {
            ref.bench[decoration].c.current.visible = sizeOkLayoutC
            ref.bench[decoration].c1.current.children[0].visible = layout === 'c'
            ref.bench[decoration].c2.current.visible = layout === 'c'
            ref.bench[decoration].c1.current.children[1].visible = layout === 'c_mirrored'
            ref.bench[decoration].c3.current.visible = layout === 'c_mirrored'

            if (!sizeOkLayoutC) {
                resetBench()
            }

            const offset = .103 // відстань від бокової стіни до спинкою
            const offset2 = .566 // ширина лавки

            if (model.name === 'athen' && layout === 'c_mirrored') {

                ref.bench.wrapper.current.rotation.y = Math.PI / 2
                ref.bench.wrapper.current.position.z = 2 - size.model.wallThickness

                /* Back bench */
                ref.bench[decoration].c1.current.children[1].scale.x = (depth - offset) / (benchInitSize.depth - offset)
                ref.bench[decoration].c1.current.children[2].scale.x = depth / benchInitSize.depth
                ref.bench[decoration].c1.current.position.x = - (depthOut - benchInitSize.depth)

                // Нижні дошки
                const len = ref.bench[decoration].c1.current.children[2].children.length
                ;[1.7, 1.6, 1.5].forEach((width, i) => {
                    console.log( width, !(size.model.width < width))
                    ref.bench[decoration].c1.current.children[2].children[len - i - 1].visible = !(size.model.width < width)
                })

                /* Side Bench */
                ref.bench[decoration].c3.current.position.x = - .16
                const scaleWidth = (width - offset) / (benchInitSize.width - offset)
                const scaleWidth2 = (width - offset2) / (benchInitSize.width - offset2)
                ;['c2', 'c3'].forEach(c => {
                    ref.bench[decoration][c].current.children[0].scale.z = scaleWidth
                    ref.bench[decoration][c].current.children[0].position.z = - (offset * scaleWidth - offset)
                    ref.bench[decoration][c].current.children[1].scale.z = scaleWidth2
                    ref.bench[decoration][c].current.children[1].position.z = - (offset2 * scaleWidth2 - offset2)
                })

            } else {

                const scaleWidth = (width - offset) / (benchInitSize.width - offset)
                const scaleDepth = (depth - offset) / (benchInitSize.depth - offset)
                const scaleDepth2 = (depth - offset2) / (benchInitSize.depth - offset2)

                /* Back bench */
                ref.bench[decoration].c1.current.position.x = 0
                ref.bench[decoration].c1.current.children[0].scale.x = scaleWidth
                ref.bench[decoration].c1.current.children[0].position.x = - (offset * scaleWidth - offset)
                ref.bench[decoration].c1.current.children[1].scale.x = scaleWidth
                ref.bench[decoration].c1.current.children[2].scale.x = width / benchInitSize.width

                // Нижні дошки
                const len = ref.bench[decoration].c1.current.children[2].children.length
                ;[1.7, 1.6, 1.5].forEach((dep, i) => {
                    ref.bench[decoration].c1.current.children[2].children[len - i - 1].visible = !(size.model.depth < dep)
                })

                /* Side Bench */
                ref.bench[decoration].c3.current.position.x = width - benchInitSize.width
                ;['c2', 'c3'].forEach(c => {
                    ref.bench[decoration][c].current.children[0].scale.z = scaleDepth
                    ref.bench[decoration][c].current.children[0].position.z = - (offset * scaleDepth - offset)
                    ref.bench[decoration][c].current.children[1].scale.z = scaleDepth2
                    ref.bench[decoration][c].current.children[1].position.z = - (offset2 * scaleDepth2 - offset2)
                })
            }
        }

        /* Layout B */
        if (layout === 'b') {
            ref.bench[decoration].b.current.scale.x = (size.model.width - size.model.wallThickness*2) / benchInitSize.width
            ref.bench[decoration].b.current.position.y = 0

            const len = ref.bench[decoration].b.current.children.length
            ;[1.7, 1.6, 1.5, 'true', 'true'].forEach((dep, i) => {
                ref.bench[decoration].b.current.children[len - i - 1].visible = dep === 'true' || !(size.model.depth < dep)
            })

            if ((widthOut < 1.7 && depthOut < 1.7) || depthOut <= 1.3 || widthOut <= 1.3) {
                ref.bench[decoration].b.current.children.forEach((e, i, arr) => e.visible = i <= arr.length - 6)
            }
        }

        /* Layout D */
        if (layout === 'd') {
            ref.bench[decoration].d.current.visible = sizeOkLayoutD
            ref.bench[decoration].d1.current.scale.z = depth / benchInitSize.depth
            ref.bench[decoration].d2.current.scale.z = depth / benchInitSize.depth
            ref.bench[decoration].d2.current.position.x = width - benchInitSize.width
            ref.bench[decoration].d3.current.scale.x = width / benchInitSize.width

            if (config[configName.model].value.optionsSelected[0].name === 'athen') {
                ref.bench[decoration].d2.current.scale.z = (size.model.wRight - size.model.wallThickness - .4) / benchInitSize.depth
            }

            if (!sizeOkLayoutD) {
                resetBench()
            }
        }

        /* Stool */
        (() => {
            if (layout === 'b') {
                ref.bench.stool.current.visible = (widthOut < 1.7 && depthOut < 1.7) || depthOut <= 1.3 || widthOut <= 1.3
                ref.bench.stool.current.rotation.y = 0
                ref.bench.stool.current.position.x = sideGlassRight ? .02 : width - stoolInitSize.width - .02
                ref.bench.stool.current.position.y = - .275
                ref.bench.stool.current.position.z = .59

                if (model.name === 'athen') {
                    ref.bench.stool.current.position.x = .63
                }
            }
        })()

    }, [layout, decoration, size])

    // Render
    return <group ref={ref.bench.wrapper}>
        <Standart material={material}/>
        <Prestige material={material}/>
        <Exclusiv material={material}/>
        <Luxus    material={material}/>
        <Comfort  material={material}/>
        <Hocker_massiv material={material}/>
    </group>
}