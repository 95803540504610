import React, {useContext, useEffect, useRef, useMemo} from 'react'
import {Base, Geometry, Subtraction} from '@react-three/csg'
import {OptionsContext} from '../../App.js'
import {configName} from '../../helpers/constants.js'
import {useGLTF} from '@react-three/drei'
import * as THREE from 'three'

const models = {
    eckleuchte_standard: '/assets/models/lamp/Lampenschirm_Standard.glb',
    eckleuchte_prestige: '/assets/models/lamp/Lampenschirm_Exklusiv.glb',
    led_spot: '/assets/models/lamp/LED_Spot.glb',
    led_spot_bronze: '/assets/models/lamp/LED_Spot_bronze.glb',
}
Object.keys(models).forEach(name => useGLTF.preload(models[name]))

export default function Lamp({ size }) {
    const { config } = useContext( OptionsContext )
    const model = config[configName.model].value.optionsSelected[0]
    const lightsRef = useRef()

    useEffect(() => {
        const ids = config[configName.lamp].value.optionsSelected.map(lamp => lamp.id)

        lightsRef.current.children.forEach((light, i) => {
            light.visible = ids.includes(i + 1)
        })

    }, [config[configName.lamp].value])

    const positionRoundLamps = [
        model.name === 'athen' ? size.model.width - .5
            : model.name === 'kristall' ? size.model.width / 2
                : size.model.width >= 1.7 ? size.model.width / 2
                    : size.glassElem.side === 'R' ? size.model.wallThickness + size.door.width / 2
                        : size.model.width - size.model.wallThickness - size.door.width / 2,
        size.model.height - size.model.wallThickness,
        model.name === 'athen' ? size.model.depth - .5 : size.model.depth - size.model.wallThickness * 3
    ]

    return <group ref={lightsRef}>
        <WallSconce1 visible={false} size={size}/>
        <WallSconce2 visible={false} size={size}/>
        <SquareLamp visible={false} size={size} color="red"/>
        <SquareLamp visible={false} size={size} color="blue"/>
        <RoundLamp visible={false} size={size} color="black" position={positionRoundLamps}/>
        <RoundLamp visible={false} size={size} color="bornze" position={positionRoundLamps}/>
        <LampsUnderBenches visible={false} size={size}/>
        <ManySmallLamps visible={false} size={size}/>
    </group>
}

function WallSconce1({size, ...props}) {
    const { config } = useContext( OptionsContext )
    let position, rotation
    if (config[configName.model].value.optionsSelected[0].name === 'athen') {
        position = [.06, size.model.height - .495, .33]
        rotation = [0, Math.PI / 4 * 3, Math.PI / 2]
    } else {
        position = [size.model.width - .3, size.model.height - .495, size.model.wallThickness - .02]
        rotation = [0, Math.PI / 4, Math.PI / 2]
    }

    return (
        <group
            name="Eckleuchte Standard"
            position={position}
            rotation={rotation}
            {...props}
        >
            <pointLight color={ '#ffc800' } position={[.2, 0, .2]} intensity={1} />
            <primitive object={useGLTF(models.eckleuchte_standard).scene} />
        </group>
    )
}

function WallSconce2({size, ...props}) {
    const { config } = useContext( OptionsContext )
    let position, rotationY
    if (config[configName.model].value.optionsSelected[0].name === 'athen') {
        position = [.075, size.model.height - .495, .26]
        rotationY = Math.PI / 4
    } else {
        position = [size.model.width - .25, size.model.height - .495, size.model.wallThickness - .01]
        rotationY = - Math.PI / 4
    }

    return (
        <group
            name="Eckleuchte Prestige"
            position={position}
            rotation-y={rotationY}
            {...props}
        >
            <pointLight color={'#ffc800'} position={[.1, .2, .02]} intensity={.8}/>
            <primitive object={useGLTF(models.eckleuchte_prestige).scene}/>
        </group>
    )
}

function SquareLamp({size, color, ...props}) {
    const colors = ['#e69539', '#e69539', '#e69539', color === 'red' ? '#e3092a' : '#326bdb', '#e69539', '#e69539']
    return (
        <group
            name="LED Farblicht Lumina S"
            position={[size.model.width / 2, size.model.height - .09, size.model.depth / 2]}
            {...props}
        >
            <mesh position-y={.023}>
                <boxGeometry args={[.23, .027, .51]}/>
                {colors.map((color, i) => <meshStandardMaterial key={i} attach={`material-${i}`} color={color}/>)}
            </mesh>
            <mesh>
                <Geometry useGroups>
                    <Base>
                        <boxGeometry args={[.27, .02, .56]}/>
                        <meshStandardMaterial color={'#e69539'}/>
                    </Base>

                    <Subtraction>
                        <boxGeometry args={[.23, .02, .51]}/>
                        <meshStandardMaterial color={'#e69539'}/>
                    </Subtraction>
                </Geometry>
            </mesh>

            <rectAreaLight
                color={ color === 'red' ? '#ff1612' : '#336ad6' }
                intensity={1}
                width={1}
                height={1}
                position={[.2, 0, - .07]}
                rotation={[Math.PI * 1.5, 0, 0]}
            />
        </group>
    )
}

function RoundLamp({size, color, ...props}) {
    const spotlight = new THREE.SpotLight('#f8be11')

    return (
        <group name={"LED Spot " + color} {...props}>
            <primitive
                object={spotlight}
                intensity={1}
                distance={3}
                angle={50 * (Math.PI / 180)}
                penumbra={1}
                decay={.5}
                position={[0, .08, 0]}
            />
            <primitive key="light_target" object={spotlight.target} position={[0, 0, 0]} />
            {/*<primitive key="light_helper" object={spotlightHelper} position={[0, 0, 0]} />*/}

            <primitive
                rotation-z={Math.PI}
                scale={.1}
                object={useGLTF(color === 'black' ? models.led_spot : models.led_spot_bronze).scene}
            />

        </group>
    )
}

function LampsUnderBenches({size, ...props}) {
    const { config } = useContext( OptionsContext )
    const lampRef = useRef()

    useEffect(() => {
        if (lampRef.current) {
            const layout = config[configName.benchLayout].value.optionsSelected[0]
            const decoration = config[configName.benchOption].value.optionsSelected[0]

            // venus, athen, kristall, morena
            // layout_c, layout_b, layout_c_mirrored, layout_d, keine
            // standard, prestige, exclusive, lux, comfort

            const benchBack = {
                height: [.1, .3, .3, .3, .2],
                positionY: [1.03, 1.1, 1.16, 1.1, 1.1],
            }

            for (const key in lampRef.current.children) {
                const lamp = lampRef.current.children[key]
                if (key === '0') {
                    lamp.visible = layout.name !== 'keine'
                    lamp.width = size.model[layout.name === 'layout_d' ? 'depth' : 'width'] - size.model.wallThickness * 2 - .2
                    lamp.height = benchBack.height[decoration.id]
                    lamp.position.set(
                        layout.name === 'layout_d' ? size.model.width - size.model.wallThickness - .001 : size.model.width / 2,
                        benchBack.positionY[decoration.id],
                        layout.name === 'layout_d' ? size.model.depth / 2 + .1 : size.model.wallThickness + .001
                    )
                    lamp.rotation.set(
                        Math.PI,
                        layout.name === 'layout_d' ? Math.PI / 2 : 0,
                        0
                    )
                    lamp.intensity = decoration.name === 'standard' ? 10 : 3
                }
                if (key === '1') {
                    lamp.visible = layout.name !== 'keine'
                    lamp.width = size.model[layout.name === 'layout_d' ? 'depth' : 'width'] - size.model.wallThickness * 2
                    lamp.height = .1
                    lamp.position.set(
                        layout.name === 'layout_d' ? size.model.width - .4 : size.model.width / 2,
                        .7,
                        layout.name === 'layout_d' ? size.model.depth / 2 : .4
                    )
                    lamp.rotation.set(
                        - Math.PI / 2,
                        0,
                        layout.name === 'layout_d' ? Math.PI / 2 : 0
                    )
                }
                if (key === '2') {
                    lamp.visible = layout.name !== 'keine'
                    lamp.width = size.model.width - size.model.wallThickness * 2
                    lamp.height = .1
                    lamp.position.set(size.model.width / 2, .279, .9)
                    lamp.rotation.set(- Math.PI / 2, 0, 0)
                }
                if (key === '3') {
                    lamp.visible = layout.name !== 'keine' && layout.name !== 'layout_b'
                    lamp.width = size.model.depth - size.model.wallThickness * 2 - .2
                    lamp.height = benchBack.height[decoration.id]
                    lamp.position.set(
                        layout.name === 'layout_c_mirrored' ? size.model.width - size.model.wallThickness - .001 : size.model.wallThickness + .001,
                        benchBack.positionY[decoration.id],
                        size.model.depth / 2 + .1
                    )
                    lamp.rotation.set(
                        Math.PI,
                        layout.name === 'layout_c_mirrored' ? Math.PI / 2 : - Math.PI / 2,
                        0
                    )
                    lamp.intensity = decoration.name === 'standard' ? 10 : 3
                }
                if (key === '4') {
                    lamp.visible = layout.name !== 'keine' && layout.name !== 'layout_b'
                    lamp.width = size.model.depth - size.model.wallThickness * 2 - (layout.name === 'layout_d' ? 0 : .55)
                    lamp.height = .1
                    lamp.position.set(
                        layout.name === 'layout_c_mirrored' ? size.model.width - .4 : .4,
                        .7,
                        layout.name === 'layout_d' ? size.model.depth / 2 : size.model.depth / 2 + .27,
                    )
                    lamp.rotation.set(- Math.PI / 2, 0, Math.PI / 2)
                }
            }
        }
    }, [
        config[configName.size].value,
        config[configName.lamp].value,
        config[configName.benchLayout].value,
        config[configName.benchOption].value
    ])

    const lights = useMemo(() => {
        const lights = []
        const color = new THREE.Color('#ffa600')
        // const colors = ['#b62d35', '#e53079', '#13d0e3', '#54c230', '#486fee']
        for (let i = 0; i < 5; i++) {
            const rectAreaLight = new THREE.RectAreaLight(color, 10, 1, .1)
            // const rectAreaLightHelper = new RectAreaLightHelper(rectAreaLight, new THREE.Color(colors[i]))
            // rectAreaLight.add( rectAreaLightHelper )
            lights.push(<primitive key={i} object={rectAreaLight}/>)
        }

        return <group ref={ lampRef }>{ lights }</group>
    }, [])

    return (
        <group name="LED indirekte Beleuchtung" {...props}>{ lights }</group>
    )
}

function ManySmallLamps({size, ...props}) {
    const lamps = useMemo(() => {
        const { width, depth, wallThickness } = size.model,
            padding = .2,
            minDistance = .2

        const w = width - wallThickness * 2 - padding
        const d = depth - wallThickness * 2 - padding
        const count = {
            x: Math.trunc(w / minDistance ),
            y: Math.trunc(d / minDistance )
        }
        const distance = {
            x: w / count.x,
            y: d / count.y
        }

        const arrayLamps = []

        for (let i = 0; i < count.x; i++) {
            for (let j = 0; j < count.y; j++) {
                if (j%2 !== i%2) {
                    arrayLamps.push(
                        <group key={`${i}_${j}`} position={[distance.x * i, .0085, distance.y * j]}>
                            <mesh
                                rotation-x={Math.PI/2}
                                geometry={new THREE.RingGeometry(0, .0036, 32)}
                                material={new THREE.MeshBasicMaterial({ color: 0xffffff })}
                            />
                            <mesh
                                rotation-x={- Math.PI/2}
                                geometry={new THREE.RingGeometry(.0048, .0036, 32)}
                                material={new THREE.MeshBasicMaterial({ color: 0x000000 })}
                            />
                        </group>
                    )
                }
            }
        }

        // - (size.model.height - .091)
        arrayLamps.push(
            <rectAreaLight
                key="lightt_key"
                color={ '#ffea00' }
                intensity={1}
                width={size.model.width / 3}
                height={size.model.depth / 3}
                position={[(size.model.width - .6) / 2, - .2, (size.model.depth - .6) / 2]}
                rotation={[Math.PI * 1.5, 0, 0]}
            />
        )

        return <group position={[.305, size.model.height - .0892, .305]}>{ arrayLamps }</group>

    }, [size])
    return (
        <group name="Sternenhimmel" {...props}>{ lamps }</group>
    )
}

