import React, {useContext, useMemo} from 'react'
import {configName} from '../../../helpers/constants'
import {OptionsContext} from '../../../App.js'

export default function AthenVerticalBars({size, wall, texture}) {

    const { config } = useContext( OptionsContext )

    return useMemo(() => {
        const additionalOutside = config[configName.construction].value.optionsSelected[0]
        const cassetteDesign = config[configName.construction2].value.optionsSelected[0]

        if (!(additionalOutside.id === 4 || cassetteDesign.id === 0)) return null

        const railWidth = size.model.wallThickness
        const railVerPosZ = size.model.wallThickness / 2 + .001

        const wallKey = {
            corner: 'wCorner',
            front: 'wFront',
            right: 'wRight',
            left: 'depth',
            back: 'width',
        }

        const posX = size.model[wallKey[wall]] / 2 - railWidth / 2
        const posMiddle = (size.model[wallKey[wall]] - railWidth) / 6
        let arrayBars

        if (wall === 'corner') {
            arrayBars = [posX, - posX]
        } else {
            const width = ['front', 'back'].includes(wall) ? size.model.width : size.model.depth
            const breakPoint = 1.7
            const breakPoint2 = 2.3

            if (['front', 'right'].includes(wall)) {
                if (width < breakPoint) {
                    arrayBars = [posX, - posX]
                }
                else if (width >= breakPoint && width < breakPoint2) {
                    arrayBars = [posX, 0, - posX]
                }
                else {
                    arrayBars = [posX, posMiddle, - posMiddle, - posX]
                }
            } else {
                if (width < breakPoint) {
                    arrayBars = [posX, 0, - posX]
                }
                else {
                    arrayBars = [posX, posMiddle, - posMiddle, - posX]
                }
            }
        }

        const Bars = ({ name, args, position }) => {
            return (
                <mesh name={name} position={position}>
                    <planeGeometry args={args} />
                    <meshStandardMaterial
                        map={ name === 'internal' ? texture.in.bar.ver : texture.out.bar.ver}
                        side={ name === 'internal' ? 0 : 1 }
                    />
                </mesh>
            )
        }

      return arrayBars.map((x, i, array) => {
          const first = i === 0
          const last = i === array.length - 1
          let offsetX = .0165
          let innerX = x + (first ? -offsetX : last ? +offsetX : 0)
          let width = (first || last) ? railWidth - offsetX * 2 : railWidth
          let height = size.model.height

          const sideLeft = size.glassElem.side === 'L'

            if (wall === 'front') {
               if (first) {
                   innerX = x - railWidth / 4 * 3
                   width = railWidth / 2

                   if ((size.glassElem.name === 'teilglasfront' && sideLeft) || (size.model.width < 1.7)) {
                       width = 0 // hide rail
                   }
               }

                if ((size.glassElem.name === 'teilglasfront' && sideLeft) && !first && !last) {
                    height = 0 // hide rail
                }
            }

            if (wall === 'right') {
                if (last) {
                    innerX = x + railWidth / 4 * 3
                    width = railWidth / 2

                    if ((size.glassElem.name === 'teilglasfront' && !sideLeft) || (size.model.depth < 1.7)) {
                        width = 0 // hide rail
                    }
                }

                if ((size.glassElem.name === 'teilglasfront' && !sideLeft) && !first && !last) {
                    height = 0 // hide rail
                }
            }

            if ((wall === 'left' || wall === 'back') && (first || last)) {
                offsetX = railWidth / 4 * 3
                innerX = x + (first ? - offsetX : last ? + offsetX : 0)
                width = railWidth / 2
            }

            if (['glaseck', 'teilglasfront'].includes(size.glassElem.name)) {
                if (    sideLeft && wall === 'front'  && last
                    ||  sideLeft && wall === 'corner' && first
                    || !sideLeft && wall === 'right'  && first
                    || !sideLeft && wall === 'corner' && last ) return
            }

            return (
                <group key={i}>
                    {additionalOutside.id === 0
                        ? <Bars name="external" args={[railWidth, height]} position={[x, 0, -railVerPosZ]}/>
                        : null}
                    {cassetteDesign.id === 0
                        ? <Bars name="internal" args={[width, height]} position={[innerX, 0, railVerPosZ]}/>
                        : null}
                </group>
            )
        })

    }, [size, texture, config[configName.construction2].value, config[configName.construction].value])
}
