/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 Standart.glb 
*/

import React, {useContext} from 'react'
import { useGLTF } from '@react-three/drei'
import {OptionsContext} from '../../App.js'

export function Standart({material, ...props}) {
  const { nodes } = useGLTF('/assets/models/bench/Standart.glb')
    const {ref} = useContext(OptionsContext)

  return (
    <group {...props} dispose={null} ref={ref.bench.standard.set} name="Standart" visible={false}>
        <group ref={ref.bench.standard.c}>
            <group ref={ref.bench.standard.c1}>
                <group>
                    <mesh geometry={nodes.Cube026.geometry} material={material} />
                    <mesh geometry={nodes.Cube027.geometry} material={material} />
                </group>

                <group>
                    <mesh geometry={nodes.Cube078.geometry} material={material} />
                    <mesh geometry={nodes.Cube079.geometry} material={material} />
                </group>

                <group>
                    <mesh geometry={nodes.Cube048.geometry} material={material} />
                    <mesh geometry={nodes.Cube047.geometry} material={material} />
                    <mesh geometry={nodes.Cube046.geometry} material={material} />
                    <mesh geometry={nodes.Cube045.geometry} material={material} />
                    <mesh geometry={nodes.Cube044.geometry} material={material} />
                    <mesh geometry={nodes.Cube043.geometry} material={material} />

                    <mesh geometry={nodes.Cube042.geometry} material={material} />
                    <mesh geometry={nodes.Cube041.geometry} material={material} />
                    <mesh geometry={nodes.Cube040.geometry} material={material} />
                    <mesh geometry={nodes.Cube039.geometry} material={material} />
                    <mesh geometry={nodes.Cube051.geometry} material={material} />
                    <mesh geometry={nodes.Cube050.geometry} material={material} />
                    <mesh geometry={nodes.Cube049.geometry} material={material} />

                    <mesh geometry={nodes.Cube038.geometry} material={material} />
                    <mesh geometry={nodes.Cube037.geometry} material={material} />
                    <mesh geometry={nodes.Cube036.geometry} material={material} />
                    <mesh geometry={nodes.Cube035.geometry} material={material} />
                    <mesh geometry={nodes.Cube034.geometry} material={material} />
                </group>
            </group>
            <group ref={ref.bench.standard.c2}>
                <group>
                    <mesh geometry={nodes.Cube024.geometry} material={material} />
                    <mesh geometry={nodes.Cube025.geometry} material={material} />
                </group>

                <group>
                    <mesh geometry={nodes.Cube014.geometry} material={material} />
                    <mesh geometry={nodes.Cube013.geometry} material={material} />
                    <mesh geometry={nodes.Cube008.geometry} material={material} />
                    <mesh geometry={nodes.Cube007.geometry} material={material} />
                    <mesh geometry={nodes.Cube002.geometry} material={material} />
                    <mesh geometry={nodes.Cube001.geometry} material={material} />
                </group>
            </group>
            <group ref={ref.bench.standard.c3}>
                <group>
                    <mesh geometry={nodes.Cube055.geometry} material={material} />
                    <mesh geometry={nodes.Cube056.geometry} material={material} />
                </group>

                <group>
                    <mesh geometry={nodes.Cube033.geometry} material={material} />
                    <mesh geometry={nodes.Cube032.geometry} material={material} />
                    <mesh geometry={nodes.Cube031.geometry} material={material} />
                    <mesh geometry={nodes.Cube030.geometry} material={material} />
                    <mesh geometry={nodes.Cube029.geometry} material={material} />
                    <mesh geometry={nodes.Cube028.geometry} material={material} />
                </group>
            </group>
        </group>

        <group ref={ref.bench.standard.b}>
            <group>
                <mesh geometry={nodes.Cube015.geometry} material={material} />
                <mesh geometry={nodes.Cube017.geometry} material={material} />
            </group>

            <mesh geometry={nodes.Cube048.geometry} material={material} />
            <mesh geometry={nodes.Cube047.geometry} material={material} />
            <mesh geometry={nodes.Cube046.geometry} material={material} />
            <mesh geometry={nodes.Cube045.geometry} material={material} />
            <mesh geometry={nodes.Cube044.geometry} material={material} />
            <mesh geometry={nodes.Cube043.geometry} material={material} />

            <mesh geometry={nodes.Cube042.geometry} material={material} />
            <mesh geometry={nodes.Cube041.geometry} material={material} />
            <mesh geometry={nodes.Cube040.geometry} material={material} />
            <mesh geometry={nodes.Cube039.geometry} material={material} />
            <mesh geometry={nodes.Cube051.geometry} material={material} />
            <mesh geometry={nodes.Cube050.geometry} material={material} />
            <mesh geometry={nodes.Cube049.geometry} material={material} />

            <mesh geometry={nodes.Cube038.geometry} material={material} />
            <mesh geometry={nodes.Cube037.geometry} material={material} />
            <mesh geometry={nodes.Cube036.geometry} material={material} />
            <mesh geometry={nodes.Cube035.geometry} material={material} />
            <mesh geometry={nodes.Cube034.geometry} material={material} />
        </group>

        <group ref={ref.bench.standard.d}>
            <group ref={ref.bench.standard.d1}>
                <mesh geometry={nodes.Cube080.geometry} material={material} />
                <mesh geometry={nodes.Cube081.geometry} material={material} />

                <mesh geometry={nodes.Cube009.geometry} material={material} />
                <mesh geometry={nodes.Cube010.geometry} material={material} />
                <mesh geometry={nodes.Cube011.geometry} material={material} />
                <mesh geometry={nodes.Cube012.geometry} material={material} />
                <mesh geometry={nodes.Cube016.geometry} material={material} />
                <mesh geometry={nodes.Cube018.geometry} material={material} />
            </group>

            <group ref={ref.bench.standard.d2}>
                <mesh geometry={nodes.Cube019.geometry} material={material} />
                <mesh geometry={nodes.Cube020.geometry} material={material} />

                <mesh geometry={nodes.Cube021.geometry} material={material} />
                <mesh geometry={nodes.Cube022.geometry} material={material} />
                <mesh geometry={nodes.Cube023.geometry} material={material} />
                <mesh geometry={nodes.Cube052.geometry} material={material} />
                <mesh geometry={nodes.Cube053.geometry} material={material} />
                <mesh geometry={nodes.Cube054.geometry} material={material} />
            </group>

            <group ref={ref.bench.standard.d3}>
                <mesh geometry={nodes.Cube038.geometry} material={material} position={[0, 0, -.0908]}/>
                <mesh geometry={nodes.Cube038.geometry} material={material} />
                <mesh geometry={nodes.Cube037.geometry} material={material} />
                <mesh geometry={nodes.Cube036.geometry} material={material} />
                <mesh geometry={nodes.Cube035.geometry} material={material} />
                <mesh geometry={nodes.Cube034.geometry} material={material} />
            </group>
        </group>


    </group>
  )
}

useGLTF.preload('/assets/models/bench/Standart.glb')
